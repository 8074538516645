import { makeAutoObservable } from "mobx";
import ViewModel from "../../ViewModel";
import TelegramService from "../../../services/TelegramService";




export default class SetChatIdVM implements ViewModel {

    onClose: () => void;

    chatId: string;

    constructor(onClose?: () => void) {
        this.onClose = onClose;
        makeAutoObservable(this);
    }

    setChatId = (value: string) => {
        let result = value.replace(/[^0-9]/g, '');
        this.chatId = result;
    }


    onSubmit = async () => {
        await TelegramService.setTgChatId(this.chatId);
        this.onClose();
    }
}