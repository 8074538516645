import { FC } from "react";
import styled from "styled-components";



const Container = styled.div`
    min-height: 70px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;



const Footer: FC = () => {
    return (
        <Container>
            Связаться с нами : gsmscales@gmail.com
        </Container>)
}

export default Footer;