import { observer } from 'mobx-react-lite';
import { Segment } from 'semantic-ui-react';
import Modal from '../../Containers/Modal/Modal';
import ChartViewModel from '../ChartViewModel';


interface IChartProps {

    viewModel: ChartViewModel;
}

function Chart({ viewModel }: IChartProps) {




    return (
        <Segment rised>
            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
            <Modal viewModel={viewModel.CurrentModal}>{viewModel.CurrentModalContent}</Modal>
        </Segment>
    );
}
export default observer(Chart);
