import { observer } from "mobx-react-lite";
import React from 'react';
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Context } from "..";



interface Expandable {
    expanded?: boolean;
}



const Container = styled.div`
    cursor: pointer;
    position:relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 100px;
    align-items: center;
    justify-content: center;

    @media(max-width: 500px){
        max-width: 100%;
        width: 100%;
        height: 100%;
        background-color: #F9B863;
        border: 2px solid #F9B863;
        border-bottom: 2px solid black;
    }
`;

const List = styled.ul`
    display: ${(props: Expandable) => props.expanded ? 'flex' : 'none'};
    position: absolute;
    flex-direction: column;
    top: 100%;
    width: min-content;
    height:max-content;
    
    
    z-index:999;
    li{
        min-height: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        background-color: #f9b863;
    }

    li:hover{
        background-color: #ffffe6;
        color:black;
    }
    @media(max-width: 500px){
        background-color: #F9B863;
        width: 100%;
    }
`;



const LoginDisplay: FC = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const { store } = useContext(Context);
    const navigate = useNavigate();

    const logout = () => {
        store.logout();
        navigate('/login');
    }
    return (
        <>
            {store.isAuthenticate
                ? <Container onClick={() => setIsExpanded(!isExpanded)} onMouseLeave={() => setIsExpanded(false)}>
                    <span>
                        {store.user.name}
                    </span>

                    <List expanded={isExpanded}>
                        <li onClick={() => navigate('/Profile')}><div >Профиль</div></li>
                        <li onClick={logout}><div >Выход</div></li>
                    </List>

                </Container >
                : <Container onClick={() => navigate('/login')}>
                    <span>
                        Войти
                    </span>



                </Container >}
        </>
    )
};

export default observer(LoginDisplay);