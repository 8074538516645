import React from 'react';
import { FC } from 'react';
import { createGlobalStyle } from 'styled-components';


const Global = createGlobalStyle`
html,
body {
    height: 100%;
    line-height: 1;
    font-size: 14px;
    background-color: black;
    color: black;
    font-family: 'Balsamiq Sans', cursive;
}

*,
*::before,
*::after {
    margin:0;
    padding:0;
    border:0;
    box-sizing:border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    scrollbar-width: thin;
    scrollbar-color: #F9B863 black;
}

*::-webkit-scrollbar {
  height: 1px;
  width: 1px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: #F9B863;
}

:focus, :active {
    outline: none;
}

a {
    color: #ffffe3;
}

a:focus, a:active {
    outline: none;
}

a, a:visited, a:hover {
    text-decoration: none;
}

button {
    cursor: pointer;
}

    button::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

ul,
ol,
li {
    list-style: none;
    margin: 0;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    font-size: inherit;
}
`;



const GlobalStyles: FC = () => {
    return (
        <Global />);
};

export default GlobalStyles;