import $api from "../http/";
import { AxiosResponse } from 'axios';
import { AuthResponse } from "../models/response/AuthResponse";
import IUser from "../models/IUser";

export default class AuthService {
    static async login(login: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return await $api.post<AuthResponse>('/auth/login', { login, password });
    }

    static async logout(login: string): Promise<void> {
        return await $api.post('/auth/logout', `"${login}"`);
    }

    static async logoutTotal(login: string): Promise<void> {
        return await $api.post('/auth/logout-total', `"${login}"`);
    }

    static async registration(login: string, password: string, email: string): Promise<AxiosResponse<IUser>> {
        return await $api.post<IUser>('/auth/registration', {
            login,
            password,
            email
        });
    }

    static async setPassword(id: number, password: string) {
        return await $api.post('/auth/set-password', {
            id,
            password
        });
    }

    static async resetPassword(password: string) {
        return await $api.post('/auth/reset-password', `"${password}"`);
    }

    static async refreshToken(): Promise<AxiosResponse<AuthResponse>> {
        return await $api.get<AuthResponse>('/auth/refresh-token');
    }
}