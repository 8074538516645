import { FC } from "react";
import styled from "styled-components";
import logo from '../assets/logo.png';



const Container = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    max-width: 100px;

    @media(max-width: 620px){
        display: none;
    }
`;

const LogoImage = styled.div`
    height: 60px;
    width: 60px;
    background: url('${logo}') no-repeat center/100%;
`



const Logo: FC = () => {
    return (
        <Container>
            <LogoImage></LogoImage>
        </Container>
    )
}

export default Logo;