import { makeAutoObservable } from "mobx";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { toast } from "react-toastify";
import Store from "../../store/store";
import ModalViewModel from "../Containers/Modal/ModalViewModel";
import Warning from "./infrastructure/Warning";




class LoginFormViewModel {

    username: string;
    password: string;
    passwordConfirm: string;
    email: string;

    dataStoreConfirm: boolean;
    garConfirm: boolean;

    loginMode: boolean = true;

    modal: ModalViewModel = new ModalViewModel();
    private store: Store;
    private navigate: NavigateFunction;


    constructor() {
        makeAutoObservable(this);
    }

    setUsername = (value: string) => {
        let result = value.replace(/[^0-9a-zA-Z]/g, '');
        this.username = result;
    }

    setPassword = (value: string) => {
        let result = value.replace(/[^0-9a-zA-Z]/g, '');
        this.password = result;
    }

    setPasswordConfirm = (value: string) => {
        let result = value.replace(/[^0-9a-zA-Z]/g, '');
        this.passwordConfirm = result;
    }

    setEmail = (value: string) => {
        this.email = value;
    }

    setDataStoreConfirm = (value: boolean) => {
        this.dataStoreConfirm = value;
    }

    setGarConfirm = (value: boolean) => {
        this.garConfirm = value;
    }

    setLoginMode = (value: boolean) => {
        this.loginMode = value;
    }

    setStore = (value: Store) => {
        this.store = value;
    }

    setNavigate = (value: NavigateFunction) => {
        this.navigate = value;
    }

    login = async () => {
        if (this.username === '') {
            toast.error('Введите имя пользователя');
            return;
        }
        if (this.password === '') {
            toast.error('Введите пароль');
            return;
        }

        if (await this.store.login(this.username, this.password)) {
            this.navigate('/');
        }
    }

    register = async () => {
        if (this.username === '') {
            toast.error('Введите имя пользователя!');
            return;
        }

        if (this.password === '') {
            toast.error('Введите пароль!');
            return;
        }

        if (this.passwordConfirm === '') {
            toast.error('Введите подтверждение пароля!');
            return;
        }

        if (this.password !== this.passwordConfirm) {
            toast.error('Поля "Пароль" и "Подтверждение пароля" должны совпадать!');
            return;
        }

        if (this.email === '') {
            toast.error('Введите адрес электронной почты!');
            return;
        }
        if (this.garConfirm && this.dataStoreConfirm) {
            await this.store.registration(this.username, this.password, this.email);
        }
        else {
            toast.error('Необходимо согласие на обработку и хранение личных данных, а так же подтверждение ознакомления с отказом от ответственности!')
        }
    }

    openModal = () => {
        this.modal.setChildren(React.createElement(Warning));
        this.modal.open();
    }
    
}
export default new LoginFormViewModel();