import { sleep } from "@amcharts/amcharts5/.internal/core/util/Time";
import { makeAutoObservable } from "mobx";
import beescaled from '../assets/beescaled.jpg';



export default class HomeViewModel {
    public scrolLock: boolean = true;
    public activeTop: boolean = true;
    public activeBottom: boolean = false;
    imageURL: string = `${beescaled}`;

    constructor() {
        this.scrolLock = true;
        makeAutoObservable(this);
    }

    UnlockScroll = ():void => {
        this.scrolLock = false;
        this.activeBottom = true;
        sleep(3000);
        this.activeTop = false;

    }
}
