import React from 'react';
import { FC } from 'react';
import HomeViewModel from '../ViewModels/HomeViewModel';
import HomeView from '../Views/HomeView';

const Home: FC = () => {

    const viewModel = new HomeViewModel();

    return (
        <HomeView viewModel={viewModel}/>
    );
}

export default Home;