import IUser from "../models/IUser";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { toast } from "react-toastify";

export default class Store{
    
    user: IUser;
    isAuthenticate: boolean;

    constructor() {
        this.user = {} as IUser;
        this.isAuthenticate = false;
        makeAutoObservable(this);
    }

    setAuth(value: boolean) {
        this.isAuthenticate = value;
    }

    setUser(value: IUser) {
        this.user = value;
    }


    async login(login: string, password: string): Promise<boolean> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await AuthService.login(login, password);
            localStorage.setItem('token', response.data.accessToken);
            this.setUser(response.data.user);
            this.setAuth(true);
            toast.update(currentToast, {
                render: `Добро пожаловать, ${response.data.user.name}!`,
                type: 'success',
                autoClose: 1000,
                isLoading: false
            });
            return true;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }

        
    }

    async registration(login: string, password: string, email: string) {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await AuthService.registration(login, password, email);
            toast.update(currentToast, {
                render: `${response.data.name} успешно зарегистрирован!`,
                type: 'success',
                autoClose: 1000,
                isLoading: false
            })
            await this.login(login, password);
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                render: `${e.response?.status}: ${e.response?.data}`,
                type: 'error',
                autoClose: 3000,
                isLoading: false
            });
        }
    }

    async logout() {
        try {
            await AuthService.logout(this.user.name);
            localStorage.removeItem('token');
            this.setUser({} as IUser);
            this.setAuth(false);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async logoutTotal() {
        try {
            const response = await AuthService.logoutTotal(this.user.name);
            localStorage.removeItem('token');
            this.setUser({} as IUser);
            this.setAuth(false);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async checkAuth() {
        try {
            const response = await axios.get<AuthResponse>("/api/auth/refresh-token", { withCredentials: true });
            localStorage.setItem('token', response.data.accessToken);
            this.setUser(response.data.user);
            this.setAuth(true);
        }
        catch (e) {
            console.log(e.response?.data?.message);
        }

        
    }
}