import { FC } from "react";
import { Panel } from "../../styledElements/styledContainers";




const Warning: FC = () => {
    return (
        <Panel>
            <p>
                Производителем заявлена погрешность тензодатчика в 1% от номинала, что означает, что возможны колебания веса в пределах 500 грамм. Это считается нормальной и принятой погрешностью для данного типа датчиков.
            </p>
            <br />
            <p>
                На показания весов оказывают влияние резкие перепады температуры и влажности.
            </p>
            <br />
            <p>
                Пожалуйста, учтите, что данная погрешность означает, что результаты взвешивания могут варьироваться на указанное значение. Рекомендуется проводить многократные измерения и использовать среднее значение для определения точного веса, если точность критична.
            </p>
            <br />
            <p>
                Также обращаем ваше внимание на то, что время автономной работы весовой платформы может варьироваться в зависимости от интенсивности освещения. При недостатке света время автономной работы может заметно сокращаться. Для обеспечения оптимальной работы рекомендуется использовать платформу в хорошо освещенных местах или обеспечить дополнительный источник света.
            </p >
            <br />
            <p>
                Мы не несем ответственности за возможные ошибки, возникшие в результате неправильной эксплуатации, воздействия непредвиденных обстоятельств на работу датчиков или отклонений результатов измерения от реальных значений, вызванных особенностями устройства и допустимой погрешностью.
            </p >
        </Panel>
    )
}

export default Warning;