//import 'bootstrap/dist/css/bootstrap.css';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Store from './store/store';
import GlobalStyles from './GlobalStyles';
import 'semantic-ui-less/semantic.less'
import App from './App';

interface State {
    store: Store,
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);



const store = new Store();
export const Context = React.createContext<State>({
    store,
});


root.render(
    <StrictMode>
        <BrowserRouter basename={baseUrl}>
            <Context.Provider value={{ store }}>
                <GlobalStyles />

                <App />

            </Context.Provider>
        </BrowserRouter>
    </StrictMode >
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
