import { FC } from "react";
import SetPasswordVM from "./SetPasswordVM";
import { observer } from "mobx-react-lite";
import { Form, Segment } from "semantic-ui-react";

interface Props {
    viewModel: SetPasswordVM;
}


const SetPassword: FC<Props> = ({ viewModel }) => {

    return (
        <Segment>
            <Form>
                <Form.Input
                    label='Новый пароль:'
                    type='text'
                    value={viewModel.password}
                    onChange={e => viewModel.setPassword(e.target.value)}>
                </Form.Input>

                <Form.Input
                    label='Подтверждение пароля:'
                    type='text'
                    value={viewModel.passwordConfirm}
                    onChange={e => viewModel.setPasswordConfirm(e.target.value)}>
                </Form.Input>
            </Form>
            <Form.Group inline>
                <Form.Button
                    color='black'
                    content='Отмена'
                    onClick={() => viewModel.onClose()}
                />
                <Form.Button positive
                    icon="checkmark"
                    labelPosition="right"
                    content="Сохранить"
                    onClick={() => { viewModel.onSubmit() }}
                />
            </Form.Group>
        </Segment>
    )
}

export default observer(SetPassword);