import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Form, Segment, Label, Accordion, Icon, Confirm, Input } from "semantic-ui-react";

import ScaleOptionsViewModel from "./ScaleOptionsViewModel";
import { Context } from "../../..";





interface Props {
    viewModel: ScaleOptionsViewModel;
}


const ScaleOptionsView: FC<Props> = ({ viewModel }) => {

    const { store } = useContext(Context);

    useEffect(() => {
        viewModel.fetchData();
    }, [])
    return (
        <Segment>
            <Form spellcheck='false'>
                <Accordion>
                    <Form.Field inline>
                        <Label>ID весов: {viewModel.scaleId}</Label>
                        <Label>fw v{viewModel.options?.fwVersion}</Label>
                        <Label>fs v{viewModel.options?.fsVersion}</Label>
                    </Form.Field>

                    <Accordion.Title
                        active={viewModel.accordionActiveIndex === 0}
                        index={0}
                        onClick={viewModel.onAccordionClick}
                    >
                        <Icon name='dropdown' />
                        Опции отправки и отображения данных.
                    </Accordion.Title>
                    <Accordion.Content active={viewModel.accordionActiveIndex === 0}  >

                        <Form.Input
                            label='Название улья'
                            type='text'
                            value={viewModel.options?.scaleName}
                            onChange={e => viewModel.setScaleName(e.target.value)}
                        />

                        <Form.Select
                            label='Название пасеки:'
                            value={viewModel.options?.apiaryId}
                            onChange={(e, { value }) => viewModel.setApiaryId(value as number)}
                            options={viewModel.selectApiariesOptions}
                        />

                        <Form.Input
                            label='Цвет графика:'
                            type='color'
                            value={viewModel.options?.color}
                            onChange={e => viewModel.setColor(e.target.value)}
                            input={{ style: {padding:0} }}
                            
                        />

                        <Form.Input
                            label='Период отправки данных (мин.):'
                            type='text'
                            value={viewModel.options?.timeSend}
                            onChange={e => viewModel.setTimeSend(e.target.value)}
                        />

                        <Form.Select
                            label='Режим отправки:'
                            value={viewModel.options?.sposobOtpr}
                            onChange={(e, { value }) => viewModel.setSposobOtpr(value as number)}
                            options={viewModel.selectSposobOtprOptions}
                        />

                        <Form.Input
                            label='Число попыток отправки данных:'
                            type='text'
                            value={viewModel.options?.numberOfAttempts}
                            onChange={e => viewModel.setNumberOfAttempts(e.target.value)}
                        />

                        <Form.Radio toggle
                            label=' - уведомление о разряде батареи.'
                            checked={viewModel.options?.batteryAlarm === 1}
                            onChange={(e, { checked }) => viewModel.setBatteryAlarm(checked)}
                        />
                        <Segment>
                            <Form.Radio toggle
                                label=' - контроль выхода роя.'
                                checked={viewModel.options?.sendData === 1}
                                onChange={(e, { checked }) => viewModel.setSendData(checked)}
                            />

                            <Form.Input
                                label='Вес выхода роя (грамм):'
                                type='text'
                                value={viewModel.options?.weightAlarm}
                                onChange={e => viewModel.setWeightAlarm(e.target.value)}
                            />
                        </Segment>

                        {store.user.role === "Administrator" ?
                            <Segment>
                                <Form.Radio toggle
                                    label=' - запись звука.'
                                    checked={viewModel.options?.sound === 1}
                                    onChange={(e, { checked }) => viewModel.setSound(checked)}
                                />

                                <Form.Input
                                    label='Интервал записи (мин.):'
                                    type='text'
                                    value={viewModel.options?.soundSendPeriod}
                                    onChange={e => viewModel.setSoundSendPeriod(e.target.value)}
                                />
                            </Segment>
                            :
                            ""
                        }

                        <Form.Radio toggle
                            label=' - GSM сигнализация.'
                            checked={viewModel.options?.gsmSignal === 1}
                            onChange={(e, { checked }) => viewModel.setGsmSignal(checked)}
                        />
                    </Accordion.Content>
                    <Accordion.Title
                        active={viewModel.accordionActiveIndex === 1}
                        index={1}
                        onClick={viewModel.onAccordionClick}
                    >
                        <Icon name='dropdown' />
                        Опции связи.
                    </Accordion.Title>
                    <Accordion.Content active={viewModel.accordionActiveIndex === 1}>
                        <Form.Input
                            label='Номер телефона:'
                            type='text'
                            value={viewModel.options?.telNumber}
                            onChange={e => viewModel.setTelNumber(e.target.value)}
                        />

                        <Form.Input
                            label='Имя точки доступа:'
                            type='text'
                            value={viewModel.options?.ssidAPName}
                            onChange={e => viewModel.setSsidAPName(e.target.value)}
                        />

                        <Form.Input
                            label='Пароль точки доступа:'
                            type='text'
                            value={viewModel.options?.ssidAPPassword}
                            onChange={e => viewModel.setSsidAPPassword(e.target.value)}
                        />

                        <Form.Input
                            label='Название Wi-Fi сети:'
                            type='text'
                            value={viewModel.options?.ssidName}
                            onChange={e => viewModel.setSsidName(e.target.value)}
                        />

                        <Form.Input
                            label='Пароль Wi-Fi сети:'
                            type='text'
                            value={viewModel.options?.ssidPassword}
                            onChange={e => viewModel.setSsidPassword(e.target.value)}
                        />

                        <Form.Input
                            label='Apn:'
                            type='text'
                            value={viewModel.options?.apn}
                            onChange={e => viewModel.setApn(e.target.value)}
                        />

                        <Form.Input
                            label='Название Apn:'
                            type='text'
                            value={viewModel.options?.apnName}
                            onChange={e => viewModel.setApnName(e.target.value)}
                        />

                        <Form.Input
                            label='Пароль Apn:'
                            type='text'
                            value={viewModel.options?.apnPass}
                            onChange={e => viewModel.setApnPass(e.target.value)}
                        />
                    </Accordion.Content>
                    <Form.Group inline>
                        <Form.Button
                            color='black'
                            content='Отмена'
                            onClick={() => viewModel.onClose()}
                        />
                        <Form.Button positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Сохранить"
                            onClick={() => { viewModel.save() }}
                        />
                    </Form.Group>
                    <Accordion.Title
                        active={viewModel.accordionActiveIndex === 2}
                        index={2}
                        onClick={viewModel.onAccordionClick}
                    >
                        <Icon name='dropdown' />
                        Служебные.
                    </Accordion.Title>
                    <Accordion.Content active={viewModel.accordionActiveIndex === 2}>
                        <Form.Group inline>
                            <Form.Button negative
                                content='Сброс пароля весов'
                                onClick={() => { viewModel.showConfirm() }}
                            />
                        </Form.Group>
                        <Confirm
                            header='ВНИМАНИЕ!!!'
                            content='Вы уверенны, что хотите произвести процедуру сброса пароля на весах?'
                            cancelButton='Отмена'
                            open={viewModel.openConfirm}
                            onConfirm={() => {
                                viewModel.changePassword(viewModel.scaleId);
                                viewModel.hideConfirm();
                            }}
                            onCancel={()=>viewModel.hideConfirm()} />
                    </Accordion.Content>
                </Accordion>
            </Form>
        </Segment>);
}
export default observer(ScaleOptionsView);