import { makeAutoObservable } from "mobx";
import ViewModel from "../../components/ViewModel";
import IApiary from "../../models/IApiary";
import ApiaryService from "../../services/ApiaryService";
import Store from "../../store/store";




export default class ApiaryTableViewModel implements ViewModel {

    store: Store;
    onClose: () => void;
    
    apiaries: IApiary[] = [] as IApiary[];

    openModal: boolean = false;
    editMode: boolean = false;

    editId: number|null;
    editName: string;



    constructor(store: Store, onClose?: () => void) {

      

        if (onClose !== undefined) {
            this.onClose = onClose;
        }

        this.store = store;
        makeAutoObservable(this);
    }





    setApiaries = (value: IApiary[]) => {
        this.apiaries = value;
    }

    setOpenModal = (value: boolean) => {
        this.openModal = value;
    }

    setEditMode = (value: boolean) => {
        this.editMode = value;
    }

    setEditId = (value: number) => {
        this.editId = value;
    }

    setEditName = (value: string) => {
        this.editName = value;
    }



    fetchData = async () => {
        const result = await ApiaryService.getApiaries();
        this.setApiaries(result);
    }

    add = () => {
        this.setEditName('');
        this.setEditId(null);
        this.setEditMode(false);
        this.setOpenModal(true);
    }

    edit = (id: number, name: string) => {
        this.setEditMode(true);
        this.setEditId(id);
        this.setEditName(name);
        this.setOpenModal(true);
    }

    deleteApiary = async (id: number) => {
        await ApiaryService.deleteApiary(id);
        await this.fetchData();
    }

    save = async () => {
        let result = false;
        if (this.editMode) {
            const apiary = this.apiaries.find(a => a.id === this.editId);
            apiary.name = this.editName;
            result = await ApiaryService.updateApiary(apiary);
        }
        else {
            const apiary: IApiary = { name: this.editName, userId: this.store.user.id };
            result = await ApiaryService.addApiary(apiary);
        }
        if (result) {
            this.resetModal();
            this.fetchData();
        }
    }

    resetModal = () => {
        this.setEditName('');
        this.setEditId(null);
        this.setEditMode(false);
        this.setOpenModal(false);
    }
    
}