import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from "mobx-react-lite";
import { Content, Title, Article, Shaded } from './HomeView.styles';
import HomeViewModel from '../ViewModels/HomeViewModel';



interface Props {
    viewModel: HomeViewModel;
}

const HomeView: FC<Props> = ({ viewModel }) => {



    return (
        <Content>
            <Shaded locked={!viewModel.scrolLock} active={viewModel.activeTop} originTop>
                <Title>Пасечные весы! </Title>
                {/*< Article > Превратите свою пасеку в умную и безопасную мини-ферму с нашей инновационной пасечной весовой платформой! 🐝☀️</Article>*/}

                {/*< Article > Не упустите возможность воспользоваться преимуществами солнечной энергии и повысить эффективность своего пчеловодства. Наша платформа оснащена солнечной панелью, датчиками давления, влажности, температуры и уникальным датчиком открытия крышки улья, предотвращающим кражи. Все данные будут отправляться в режиме реального времени через Wi-Fi, GSM или SMS, оставаясь всегда под вашим контролем!📲</Article>*/}

                {/*< Article >Зарегистрируйтесь на сайте beehoney.by, и получите доступ к собранным данным и информативным графикам. Улучшайте условия для своих пчел, следите за результатами и делайте свою пасеку процветающей, используя аналитику от нашей интеллектуальной системы! 🌺</Article>*/}

                < Article > Будущее пчеловодства здесь и сейчас!{/* 🚀 Имейте в виду, что мы стремимся неуклонно развивать и совершенствовать наш продукт, делая его еще более мощным и удобным для вас.Не упустите возможность стать частью нашего постоянно растущего комьюнити и изменить подход к управлению своей пасекой!Присоединяйтесь к нам прямо сейчас! 💼💡 */}</Article>
                <Article>Лучшее должно быть рядом!</Article>
            </Shaded>
            <Helmet>
                <meta property="og:title" content='BeeHoney.by' />
                <meta property="og:type" content='website' />
                <meta property="og:image" content='https://disk.yandex.ru/i/t3UtHFGW8C8reA' />
                <meta property="og:url" content='https://beehoney.by/' />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content='https://disk.yandex.ru/i/t3UtHFGW8C8reA' />
                <meta property="og:description" content='Будущее пчеловеодства рядом с вами!'/>
            </Helmet>
        </Content>
    );



}
export default observer(HomeView);
