import React, { PropsWithChildren } from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Header from './Header';
import mainbg from '../assets/mainbg.jpg';



const Container = styled.div`
    min-height: 100%;
    width: 100vw;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
`


const Content = styled.div`
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom:5px;

    background: url('${mainbg}') left fixed no-repeat;

    @media(min-width: 1280px){
        background: url('${mainbg}') center fixed no-repeat;
    }
`




const MainLayout: FC<PropsWithChildren> = (props) => {
    return (
        <Container>
            <Header />
            <Content>
                {props.children}
            </Content>
            <Footer />
        </Container>
    )
}

export default MainLayout;
