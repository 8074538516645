import { observer } from "mobx-react-lite";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FC, useEffect, useState } from 'react';
import { PrimaryButton } from "../../styledElements/styledButtons";
import { Panel, Row } from "../../styledElements/styledContainers";
import AddScaleViewModel from "./AddScaleViewModel";
import { Button, Form, Input, Label, Segment } from "semantic-ui-react";




interface Props {
    viewModel: AddScaleViewModel
}



const AddScale: FC<Props> = ({ viewModel }) => {

    

    return (
            <Form>
                <Segment>
                    <Form.Field error={!viewModel.isScaleIdValid}>
                        <Label>ID весов.</Label>
                        <Input
                            type='text'
                            onChange={e => viewModel.setScaleId(e.target.value)}
                        >
                            <input value={viewModel.scaleId} />

                        </Input>

                    </Form.Field>
                </Segment>
                <Segment>
                    <Form.Field>
                        <Button
                            disabled={!viewModel.isScaleIdValid}
                            fluid
                            primary
                            icon='plus'
                            content="Добавить"
                            onClick={() =>viewModel.attachToUser()}
                        />
                        <Button
                            fluid
                            color="black"
                            onClick={() => viewModel.onClose()}>
                            Отмена
                        </Button>
                    </Form.Field>
                </Segment>
            </Form>
    )
}

export default observer(AddScale);