import { makeAutoObservable } from "mobx";
import ITooltipConfig from "../../models/ITooltipConfig";
import TooltipConfigService from "../../services/TooltipConfigService";




export default class TooltipOptionsViewModel {
    private tooltipConfig: ITooltipConfig;
    private isOpen?: boolean = true;
    private close?: () => void;

    constructor(config: ITooltipConfig) {
        this.tooltipConfig = config;
        makeAutoObservable(this);
    }


    setIsOpen(value: boolean): void {
        this.isOpen = value;
    }

    get IsOpen(): boolean {
        return this.isOpen;
    }


    setClose(value: () => void): void {
        this.close = value;
    }

    get Close(): () => void {
        return this.close;
    }


    setIsAirTemperatureCheked(value: boolean): void {
        this.tooltipConfig.isAirTemperatureCheked = value;
    }

    get IsAirTemperatureCheked(): boolean {
        return this.tooltipConfig.isAirTemperatureCheked;
    }


    setIsWeightCheked(value: boolean): void {
        this.tooltipConfig.isWeightCheked = value;
    }

    get IsWeightCheked(): boolean {
        return this.tooltipConfig.isWeightCheked;
    }


    setIsChargeCheked(value: boolean): void {
        this.tooltipConfig.isChargeCheked = value;
    }

    get IsChargeCheked(): boolean {
        return this.tooltipConfig.isChargeCheked;
    }


    setIsSystemTemperatureCheked(value: boolean): void {
        this.tooltipConfig.isSystemTemperatureCheked = value;
    }

    get IsSystemTemperatureCheked(): boolean {
        return this.tooltipConfig.isSystemTemperatureCheked;
    }


    setIsHumidityCheked(value: boolean): void {
        this.tooltipConfig.isHumidityCheked = value;
    }

    get IsHumidityCheked(): boolean {
        return this.tooltipConfig.isHumidityCheked;
    }


    setIsPressureCheked(value: boolean): void {
        this.tooltipConfig.isPressureCheked = value;
    }

    get IsPressureCheked(): boolean {
        return this.tooltipConfig.isPressureCheked;
    }


    setIsSignalLevelCheked(value: boolean): void {
        this.tooltipConfig.isSignalLevelCheked = value;
    }

    get IsSignalLevelCheked(): boolean {
        return this.tooltipConfig.isSignalLevelCheked;
    }


    setTooltipConfig(value: ITooltipConfig): void {
        this.tooltipConfig = value;
    }

    confirm = async() => {
        await TooltipConfigService.updateConfig(this.tooltipConfig);
        this.close();
    }

    get SaveOptions() {
        return (async (): Promise<boolean> => (await TooltipConfigService.updateConfig(this.tooltipConfig)));
    }
}