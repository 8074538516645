import styled from "styled-components";
import { Directable, Sizeble } from "../../interfaces/HTMLAttributes";

interface PanelProps extends Sizeble, Directable {


}

export const Panel = styled.div`
    min-height: ${(props: PanelProps) => props.height};
    width: ${(props: PanelProps) => props.width};
    display: flex;
    flex-wrap: wrap;
    flex-direction:${(props: PanelProps) => props.direction};
    align-items:center;
    justify-content:center;
    padding: 10px;
    margin: 0px;
    border-radius: 10px;
    color: black;
    background-color: rgba(255, 255, 227, 0.95);
    box-shadow: rgba(233,209,177,0.8) 0px 0px 30px 5px;
`

export const GrayScreen = styled.div`
    position: absolute;
    background-color: #2e2d2df5;
    display: flex;
    flex-direction: column;
    padding: 15px 5px 5px;

`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 227, 0.95);
    border-radius: 2px;
    padding: 4px;
`