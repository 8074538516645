import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { Container, Card, Modal } from "semantic-ui-react";
import src from '../../assets/logo.png';
import AdministratorVM from "./AdministratorVM";



const Administrator: FC = () => {

    const [viewModel] = useState(new AdministratorVM());

    useEffect(() => {
        viewModel.fetchData();
    }, []);

    return (
        <Container>
            <Card.Group itemsPerRow={4}>
                <Card link
                    header='Коды ошибок'
                    color='red'
                    image={src}
                    onClick={viewModel.openErrors} />
                <Card link
                    header='Пользователи'
                    color='red'
                    image={src}
                    onClick={viewModel.openCustomers} />

                <Card link
                    header='Весы'
                    color='red'
                    image={src}
                    onClick={viewModel.openScales} />

                <Card link
                    header='Прошивка'
                    description={viewModel.fwVersion}
                    color='red'
                    image={src}
                    onClick={viewModel.openFirmware} />

                <Card link
                    header='Файловая система'
                    description={viewModel.fsVersion}
                    color='red'
                    image={src}
                    onClick={viewModel.openFs} />

            </Card.Group>

            <Modal
                closeIcon
                open={viewModel.modalOpened}
                onClose={() => viewModel.setModalOpened(false)}
                dimmer='blurring'
                header={viewModel.modalHeader}
                content={viewModel.modalContent}
            />

        </Container>


    );
}

export default observer(Administrator);