import { makeAutoObservable } from "mobx";




export default class ModalViewModel {
    private isOpen: boolean = false;
    private children?: any;
    private onClose: () => void;

    constructor() {
        makeAutoObservable(this);
    }

    setIsOpen = (value: boolean) => {
        this.isOpen = value;
    }

    get IsOpen(): boolean {
        return this.isOpen;
    }

    setChildren = (value: any) => {
        this.children = value;
    }

    get Children(): any {
        return this.children;
    }

    open = () => {
        this.isOpen = true;
    }

    close = () => {
        this.isOpen = false;
    }
}