import $api from "../http";
import IApiary from "../models/IApiary";
import { toast } from "react-toastify";

const autoCloseDelay = 300;

export default class ApiaryService {
    static async getApiaries(): Promise<IApiary[]> {
        //const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.get('/apiary/list');
            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: autoCloseDelay,
            //    isLoading: false
            //});

            return response.data;
        }
        catch (e){
            console.log(e.response?.data);
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose:3000
            }
                )
            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            return [] as IApiary[];
        }
    }

    static async addApiary(apiary: IApiary): Promise<boolean> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            await $api.post('/apiary/create', {} = apiary);
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }

    static async updateApiary(apiary: IApiary): Promise<boolean> {

        const currentToast = toast.loading('Ожидайте...');
        try {
            await $api.post('/apiary/update', {} = apiary);
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'success',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }

    static async deleteApiary(apiaryId: number): Promise<boolean> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            await $api.post('/apiary/delete', `${apiaryId}`);
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }
}