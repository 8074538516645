import { observer } from "mobx-react-lite";
import React, { FC, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Context } from "..";
import Trigger from "./Trigger";



interface Expandable {
    expanded?: boolean;
}


const expLiStyle: string = `
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    width: 100%;
    height:max-content;
    padding-left: 10px;
    background-color: #F9B863;
    z-index:999;
    li{
        min-height: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    li:hover{
        background-color: #ffffe6e4;
    }`;



const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-items: center;
    justify-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;

    @media(max-width: 500px){
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        grid-area: menu;
        width: 100%;
        height: 100%;
        background-color: #F9B863;
        border: 2px solid #F9B863;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
    }
`;

const List = styled.ul`
    height:100%;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: space-evenly;
    
    @media(max-width: 500px){
        display: ${(props: Expandable) =>
        props.expanded ? 'flex' : 'none'};
        ${(props: Expandable) =>
        props.expanded ? expLiStyle : ''};
    }
`



const NavMenu: FC = () => {

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const { store } = useContext(Context);

    return (
        <Container onClick={() => setIsExpanded(!isExpanded)} onMouseLeave={(e) => setIsExpanded(false)}>
            <Trigger expanded={isExpanded} />
            <List expanded={isExpanded}>

                <li>
                    <NavLink to="/">На главную</NavLink>
                </li>

                <li>
                    <NavLink to='/about'>О весах</NavLink>
                </li>

                <li>
                    <NavLink to='/scales/user-charts'>График</NavLink>
                </li>

                {store.user.role === 'Administrator' ?
                    <li>
                        <NavLink to="/administrator">Администратор</NavLink>
                    </li>
                    :
                    ''
                }
            </List>
        </Container>
    );
}

export default observer(NavMenu);