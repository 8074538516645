import { makeAutoObservable } from 'mobx';
import ScaleService from '../../../services/ScaleService';
import ViewModel from '../../ViewModel';



export default class AddScaleViewModel implements ViewModel {

    onClose: () => void;

    scaleId: number;

    isScaleIdValid: boolean = false;


    constructor(onClose: () => void) {
        this.onClose = onClose;
        makeAutoObservable(this);
    }



    setScaleId = (value: string): void => {
        let result = value.replace(/[^0-9]/g, '');

        if (result.length <= 9) {
            this.scaleId = +result;

            if (result.length === 9) {
                this.isScaleIdValid = true;
            } else {
                this.isScaleIdValid = false;
            }
        }
    }

    attachToUser = async () => {
        await ScaleService.attachToUser(this.scaleId);
        this.onClose();
    }

}

