import { toast } from "react-toastify";
import $api from "../http";
import IVersionsResponse from "../models/response/IVersionsResponse";


const autoCloseDelay = 300;

export default class FirmwareService {

    static async upload(file: File) {

        const currentToast = toast.loading('Ожидайте...');

        let formData = new FormData();

        formData.append("file", file);

        try {
            await $api.post("/firmware/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
            });
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }

        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }

    static async uploadBeta(file: File) {

        const currentToast = toast.loading('Ожидайте...');

        let formData = new FormData();

        formData.append("file", file);

        try {
            await $api.post("/firmware/upload-beta", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }

        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }

    static async getActualVersionsAsync(): Promise<IVersionsResponse> {
        try {
            let response = await $api.get("/firmware/versions");
            return response.data;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose: 3000
            })

            return {} as IVersionsResponse;
        }
        
    }
}