import { makeAutoObservable } from "mobx";
import IErrorCode from "../../../../../models/IErrorCode";
import ErrorCodesService from "../../../../../services/ErrorCodesService";
import ViewModel from "../../../../ViewModel";




export default class ErrorCodeAddVM implements ViewModel {

    onClose: () => void;

    id: number;
    name: string;
    description: string;


    constructor(onClose: () => void) {
        this.onClose = onClose;
        makeAutoObservable(this);
    }


    setId = (value: string) => {
        let result = value.replace(/[^0-9]/g, '');
        this.id = +result;
    }

    setName = (value: string) => {
        this.name = value;
    }

    setDescription = (value: string) => {
        this.description = value;
    }


    addCode = async () => {
        let code: IErrorCode = { id: this.id, name: this.name, description: this.description };

        await ErrorCodesService.createCode(code);
        this.onClose()
    }
}
