import { makeAutoObservable } from "mobx";
import { AccordionTitleProps } from "semantic-ui-react";
import IApiary from "../../../models/IApiary";
import IScaleConfig from "../../../models/IScaleConfig";
import ApiaryService from "../../../services/ApiaryService";
import ScaleService from "../../../services/ScaleService";
import ViewModel from "../../ViewModel";


interface ISelectOption {
    text: string,
    value: number
}


export default class ScaleOptionsViewModel implements ViewModel {
    onClose?: () => void;

    options: IScaleConfig;
    scaleId: number;
    apiaries: IApiary[];

    openConfirm: boolean = false;

    selectApiariesOptions: ISelectOption[];
    selectSposobOtprOptions: ISelectOption[] = [
        { text: 'GSM', value: 1 },
        { text: 'Wi-Fi', value: 2 },
        { text: 'SMS', value: 3 }]

    accordionActiveIndex: string|number = 0;

    constructor(scaleId: number, apiaries?: IApiary[], onClose?: () => void) {
        this.onClose = onClose;
        this.scaleId = scaleId;
        if (apiaries !== undefined) {
            this.apiaries = apiaries;
        }
        makeAutoObservable(this);
    }

    setAccordionActiveIndex = (value: string|number) => {
        this.accordionActiveIndex = value;
    } 

    setOptions = (value: IScaleConfig) => {
        this.options = value;
    }

    setApiaries = (value: IApiary[]) => {
        this.apiaries = value;
        let result = this.apiaries.map(el => ({ text: el.name, value: el.id }));
        this.setSelectApiariesOptions(result);
    }

    setSelectApiariesOptions = (value: ISelectOption[]) => {
        this.selectApiariesOptions = value;
    }

    setScaleName(value: string) {
        this.options.scaleName = value;
    }

    setApiaryId(value: number) {
        this.options.apiaryId = value;
    }

    setSsidAPName(value: string) {
        this.options.ssidAPName = value;
        this.options.shouldUpdate = true;
    }

    setSsidAPPassword(value: string) {
        this.options.ssidAPPassword = value;
        this.options.shouldUpdate = true;
    }

    setSsidName(value: string) {
        this.options.ssidName = value;
        this.options.shouldUpdate = true;
    }

    setSsidPassword(value: string) {
        this.options.ssidPassword = value;
        this.options.shouldUpdate = true;
    }

    setTimeSend(value: string) {
        let result = value.replace(/[^0-9]/g, '');
        this.options.timeSend = +result;
        this.options.shouldUpdate = true;
    }

    setSendData(value: boolean) {
        this.options.sendData = value ? 1 : 0;
        this.options.shouldUpdate = true;
    }

    setWeightAlarm(value: string) {
        let result = value.replace(/[^0-9]/g, '');
        this.options.weightAlarm = +result;
        this.options.shouldUpdate = true;
    }

    setSposobOtpr(value: number) {
        this.options.sposobOtpr = value;
        this.options.shouldUpdate = true;
    }

    setNumberOfAttempts(value: string) {
        let result = value.replace(/[^0-9]/g, '');
        this.options.numberOfAttempts = +result;
        this.options.shouldUpdate = true;
    }

    setTelNumber(value: string) {
        this.options.telNumber = value;
        this.options.shouldUpdate = true;
    }

    setBatteryAlarm(value: boolean) {
        this.options.batteryAlarm = value ? 1 : 0;
        this.options.shouldUpdate = true;
    }

    setApn(value: string) {
        this.options.apn = value;
        this.options.shouldUpdate = true;
    }

    setApnName(value: string) {
        this.options.apnName = value;
        this.options.shouldUpdate = true;
    }

    setApnPass(value: string) {
        this.options.apnPass = value;
        this.options.shouldUpdate = true;
    }

    setGsmSignal(value: boolean) {
        this.options.gsmSignal = value ? 1 : 0;
        this.options.shouldUpdate = true;
    }

    setMassCorrection(value: string) {
        let result = value.replace(/[^0-9]/g, '');
        this.options.massCorrection = +result;
        this.options.shouldUpdate = true;
    }

    setCalibrateFactor(value: number) {
        this.options.calibrateFactor = value;
        this.options.shouldUpdate = true;
    }

    setNullFactor(value: number) {
        this.options.null_factor = value;
        this.options.shouldUpdate = true;
    }

    setNullWeight(value: number) {
        this.options.null_weight = value;
        this.options.shouldUpdate = true;
    }

    setColor(value: string) {
        this.options.color = value;
    }

    setSound(value: boolean) {
        this.options.sound = value ? 1 : 0;
        this.options.shouldUpdate = true;
    }

    setSoundSendPeriod(value: string) {
        let result = value.replace(/[^0-9]/g, '');
        this.options.soundSendPeriod = +result;
        this.options.shouldUpdate = true;
    }

    setNewScalePassword(value: string) {
        this.options.newScalePassword = value;
        this.options.shouldUpdate = true;
    }


    showConfirm = () => {
        this.openConfirm = true;
    }

    hideConfirm = () => {
        this.openConfirm = false;
    }

    save = async () => {
        await this.updateConfig();
        this.onClose();
    }



    fetchData = async () => {
        this.setOptions(await ScaleService.getScaleConfig(this.scaleId));
        this.setApiaries(await ApiaryService.getApiaries());
    }

    async changePassword(scaleId: number): Promise<void> {
        return await ScaleService.changeScalePassword(scaleId);
    }

    onAccordionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
        this.setAccordionActiveIndex(this.accordionActiveIndex === data.index ? -1 : data.index);
    }

    private async updateConfig(): Promise<number> {
        return await ScaleService.updateScaleConfig(this.options);
    }
}