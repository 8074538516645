import styled from "styled-components";




export const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width:600px;
`

export const SwitchToggler = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 50%;
    left: 2px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const SwitchInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${SwitchToggler} {
    background: #F9B863;

    &:before {
      transform: translate(18px, -50%);
    }
  }
`;