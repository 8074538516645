import { makeAutoObservable } from "mobx";
import React, { ReactNode } from "react";
import { AccordionTitleProps } from "semantic-ui-react";
import IUser from "../../models/IUser";
import Store from "../../store/store";
import ApiaryTableViewModel from "../../Views/ApiaryTable/ApiaryTableViewModel";
import ScaleTableViewModel from "../../Views/ScaleTable/ScaleTableViewModel";
import ViewModel from "../ViewModel";
import SetPasswordVM from "../Shared/SetPassword/SetPasswordVM";
import SetPassword from "../Shared/SetPassword/SetPassword";
import SetChatId from "./ConnectTelegram/SetChatId";
import SetChatIdVM from "./ConnectTelegram/SetChatIdVM";




export default class ProfileViewModel implements ViewModel {

    private store: Store;
    onClose?: () => void;

    apiaryTable: ApiaryTableViewModel;
    scaleTable: ScaleTableViewModel;
    accordionActiveIndex: string | number = 1;

    modalOpened: boolean;

    modalHeader: string;
    modalContent: ReactNode;
    


    constructor(store: Store, onClose?: () => void) {
        
        this.store = store;
        this.onClose = onClose;

        this.apiaryTable = new ApiaryTableViewModel(store);
        this.scaleTable = new ScaleTableViewModel(store);

        makeAutoObservable(this);
    }

    openApiaryAdd = () => {

    }

    get userId(): number {
        return this.store.user.id;
    }

    get user(): IUser {
        return this.store.user;
    }



    setAccordionActiveIndex = (value: string|number) => {
        this.accordionActiveIndex = value;
    }

    onAccordionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
        this.setAccordionActiveIndex(this.accordionActiveIndex === data.index ? -1 : data.index);
    }

    setModalHeader = (value: string) => {
        this.modalHeader = value;
    }

    setModalContent = (value: ReactNode) => {
        this.modalContent = value;
    }

    setModalOpened = (value: boolean) => {
        this.modalOpened = value;
    }

    openEditor = () => {
        this.setModalContent(React.createElement(SetPassword, {
            viewModel: new SetPasswordVM(() => this.setModalOpened(false))
        }));
        this.setModalHeader('Установка нового пароля.');
        this.setModalOpened(true);
    }

    openTgEditor = () => {
        this.setModalContent(React.createElement(SetChatId, {
            viewModel: new SetChatIdVM(() => this.setModalOpened(false))
        }));
        this.setModalHeader('Подключение tg бота.');
        this.setModalOpened(true);
    }
    //openAddScaleDialog = () => {
    //    let addScaleDialogModel = new AddScaleViewModel(this.userId);
    //    addScaleDialogModel.setOnClose(this.modal.close);
    //    this.modal.setChildren(React.createElement(AddScale, { model: addScaleDialogModel }));
    //    this.modal.open();
    //}
}