import { AxiosError } from "axios";
import $api from "../http";
import ITooltipConfig from "../models/ITooltipConfig";




export default class TooltipConfigService {

    static async getConfig(userId: number): Promise<ITooltipConfig> {
        let result: ITooltipConfig;
        await $api.post('/tooltipconfig', `${userId}`)
            .then(response => {
                result = response.data;
            })
            .catch((error: AxiosError) => {
                if (error.response.status === 404) {
                    $api.post('/tooltipconfig/create', `${userId}`)
                        .then(response => {
                            result = response.data;
                        });
                }
            });
        return result;
    }

    static async updateConfig(config: ITooltipConfig): Promise<boolean> {
        return (await $api.post('/tooltipconfig/update', {} = config)).data;
    }
}