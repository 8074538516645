import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import ModalViewModel from './ModalViewModel';
import { ModalContainer, ModalContent } from './ModalStyles'
import CancelButton from '../../Buttons/CancelButton/CancelButton';


interface ModalProps {
    viewModel: ModalViewModel;
    children?: any;
}

const Modal: FC<ModalProps> = ({ viewModel, children }) => {
    if (!viewModel.IsOpen || children === null) {
        return null;
    }

    return (
        <ModalContainer onWheel={(e) => e.stopPropagation()}>
            <ModalContent onClick={(e) => e.stopPropagation()}>

                <CancelButton onClick={() => viewModel.setIsOpen(false)} position='absolute' size='20px' top='10px' right='20px' />
                {viewModel.Children}

            </ModalContent>
        </ModalContainer>
    );
};

export default observer(Modal);