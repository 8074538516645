import { toast } from "react-toastify";
import $api from "../http";

const autoCloseDelay = 300;


export default class TelegramService {

    static async setTgChatId(chatId: string): Promise<boolean> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            await $api.post('/telegram/set', `"${chatId}"`);
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }
}