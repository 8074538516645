import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Segment, Form } from "semantic-ui-react";
import ErrorCodeAddVM from "./ErrorCodeAddVM";


interface Props {
    viewModel: ErrorCodeAddVM;
}

const ErrorCodeAdd: FC<Props> = ({ viewModel }) => {


    return (
        <Segment>
            <Form>
                <Form.Input
                    label='ID'
                    type='text'
                    value={viewModel.id}
                    onChange={e => viewModel.setId(e.target.value)} />
                <Form.Input
                    label='Название'
                    type='text'
                    value={viewModel.name}
                    onChange={e => viewModel.setName(e.target.value)} />
                <Form.Input
                    label='Описание'
                    type='text'
                    value={viewModel.description}
                    onChange={e => viewModel.setDescription(e.target.value)} />

                <Form.Group inline>
                    <Form.Button
                        color='black'
                        content='Отмена'
                        onClick={() => viewModel.onClose()}
                    />
                    <Form.Button positive
                        icon="checkmark"
                        labelPosition="right"
                        content="Сохранить"
                        onClick={() => viewModel.addCode()}
                    />
                </Form.Group>
            </Form>
            
        </Segment>
    )
}

export default observer(ErrorCodeAdd);