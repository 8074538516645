import styled from "styled-components";
import { Panel } from "../../styledElements/styledContainers";




export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled(Panel)`
  position: relative;
  padding: 40px 20px 20px 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 227, 1);
`;



