import { makeAutoObservable } from "mobx";
import IUser from "../../../../models/IUser";
import UserService from "../../../../services/UserService";
import ViewModel from "../../../ViewModel";
import { ReactNode } from "react";
import SetPassword from "../../../Shared/SetPassword/SetPassword";
import SetPasswordVM from "../../../Shared/SetPassword/SetPasswordVM";
import React from "react";




export default class CustomersVM implements ViewModel {

    onClose: () => void;

    idToDelete: number;

    dataSet: IUser[] = [];
    dataTable: IUser[] = [];

    confirmEnabled: boolean;
    modalOpened: boolean;

    modalHeader: string;
    modalContent: ReactNode;

    constructor(onClose?: () => void) {
        this.onClose = onClose;
        makeAutoObservable(this);
    }

    setDataSet = (value: IUser[]) => {
        this.dataSet = value;
    }

    setDataTable = (value: IUser[]) => {
        this.dataTable = value;
    }

    setModalHeader = (value: string) => {
        this.modalHeader = value;
    }

    setModalContent = (value: ReactNode) => {
        this.modalContent = value;
    }

    setModalOpened = (value: boolean) => {
        this.modalOpened = value;
    }

    fetchData = async () => {
        let result = await UserService.getList();
        this.setDataSet(result);
        this.setDataTable(result);

    }

    openEditor = (id:number) => {
        this.setModalContent(React.createElement(SetPassword, {
            viewModel: new SetPasswordVM(() => this.setModalOpened(false), id)
        }));
        this.setModalHeader('Установка нового пароля.');
        this.setModalOpened(true);
    }

    deleteCustomer = async () => {
        await UserService.deleteUser(this.idToDelete);
    }

    openConfirmDialog = (value: number) => {
        this.idToDelete = value;
        this.confirmEnabled = true;
    }

    closeConfirmDialog = () => {
        this.fetchData();
        this.confirmEnabled = false;
    }
}