import { makeAutoObservable } from "mobx";
import React from "react";
import { ReactNode } from "react";
import ViewModel from "../ViewModel";
import ScaleGenerator from "./infrastructure/ScaleGenerator";
import ErrorCodes from "./Instruments/ErrorCodes/ErrorCodes";
import ErrorCodesVM from "./Instruments/ErrorCodes/ErrorCodesVM";
import Customers from "./Instruments/Customers/Customers";
import CustomersVM from "./Instruments/Customers/CustomersVM";
import FirmwareVM from "./Instruments/Firmware/FirmwareVM";
import Firmware from "./Instruments/Firmware/Firmware";
import FsVM from "./Instruments/Fs/FsVM";
import Fs from "./Instruments/Fs/Fs";
import FirmwareService from "../../services/FirmwareService";
import ScalesVM from "./Instruments/Scales/ScalesVM";
import Scales from "./Instruments/Scales/Scales";




export default class AdministratorVM implements ViewModel {

    onClose: () => void;
    private _firmwareService: FirmwareService;

    modalHeader: string;
    modalContent: ReactNode;
    modalOpened: boolean;
    fwVersion: string;
    fsVersion: string;

    constructor() {
        makeAutoObservable(this);
    }


    setModalHeader = (value: string) => {
        this.modalHeader = value;
    }

    setModalContent = (value: ReactNode) => {
        this.modalContent = value;
    }

    setModalOpened = (value: boolean) => {
        this.modalOpened = value;
    }

    setFwVersion = (value: string) => {
        this.fwVersion = value;
    }

    setFsVersion = (value: string) => {
        this.fsVersion = value;
    }

    openErrors = () => {
        this.setModalContent(React.createElement(ErrorCodes, {
            viewModel: new ErrorCodesVM(() => this.setModalOpened(false))
        }));
        this.setModalHeader('Коды ошибок.');
        this.setModalOpened(!this.modalOpened);
    }

    openCustomers = () => {
        this.setModalContent(React.createElement(Customers, {
            viewModel: new CustomersVM(() => this.setModalOpened(false))
        }));
        this.setModalHeader('Пользователи.');
        this.setModalOpened(!this.modalOpened);
    }

    openScales = () => {
        this.setModalContent(React.createElement(Scales, {
            viewModel: new ScalesVM(() => this.setModalOpened(false))
        }));
        this.setModalHeader('Весы.');
        this.setModalOpened(!this.modalOpened);
    }

    openFirmware = () => {
        this.setModalContent(React.createElement(Firmware, {
            viewModel: new FirmwareVM(async () => {
                this.setModalOpened(false);
                await this.fetchData();
            })
        }));
        this.setModalHeader('Прошивка.');
        this.setModalOpened(!this.modalOpened);
    }

    openFs = () => {
        this.setModalContent(React.createElement(Fs, {
            viewModel: new FsVM(async () => {
                this.setModalOpened(false);
                await this.fetchData();
            })
        }));
        this.setModalHeader('ФС.');
        this.setModalOpened(!this.modalOpened);
    }

    fetchData = async () => {
        let versions = await FirmwareService.getActualVersionsAsync();
        this.setFwVersion(versions.fwVersion);
        this.setFsVersion(versions.fsVersion);
    }
}

