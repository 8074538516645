import ru from 'date-fns/locale/ru';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import Chart from './Chart/Chart';
import ChartOptions from './ChartOptions/ChartOptions';

import { Context } from '../..';
import { Segment } from 'semantic-ui-react';
import styles from './UserCharts.module.less'
import ChartViewModel from './ChartViewModel';

registerLocale('ru', ru);
setDefaultLocale('ru');


const UserCharts: FC = () => {
    const { store } = useContext(Context);
    const [viewModel] = useState(new ChartViewModel());
    viewModel.initialize(store.user);

    return (
        <Segment className={styles.test}>
            <ChartOptions viewModel={viewModel} />
            <Chart viewModel={viewModel} />
        </Segment>
    );
}

export default observer(UserCharts);