import { observer } from "mobx-react-lite";
import React, { FC, useContext, useState } from "react";
import { Context } from "../..";
import ProfileViewModel from "./ProfileViewModel";
import { Accordion, Button, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import ApiaryTable from "../../Views/ApiaryTable/ApiaryTable";
import ScaleTable from "../../Views/ScaleTable/ScaleTable";




const Profile: React.FC = () => {

    const { store } = useContext(Context);
    const [viewModel] = useState(new ProfileViewModel(store));




    return (
        <Segment>
            <Grid container divided stackable columns={2} >
                <Grid.Column width={5}>
                    <Header as='h1'>{store.user.name}</Header>
                    <Button
                        content='Сменить пароль'
                        primary
                        icon='edit'
                        onClick={() => viewModel.openEditor()}
                    />


                    <Button
                        content='Подключить telegram'
                        primary
                        icon='edit'
                        onClick={() => viewModel.openTgEditor()}
                    />



                </Grid.Column>
                <Grid.Column width={11}>
                    <Accordion>
                        <Accordion.Title
                            active={viewModel.accordionActiveIndex === 0}
                            index={0}
                            onClick={viewModel.onAccordionClick}
                        >
                            <Icon name='dropdown' />
                            Пасеки.
                        </Accordion.Title>

                        <Accordion.Content active={viewModel.accordionActiveIndex === 0}>
                            <ApiaryTable viewModel={viewModel.apiaryTable} />
                        </Accordion.Content>

                        <Accordion.Title
                            active={viewModel.accordionActiveIndex === 1}
                            index={1}
                            onClick={viewModel.onAccordionClick}
                        >
                            <Icon name='dropdown' />
                            Весы.
                        </Accordion.Title>
                        <Accordion.Content active={viewModel.accordionActiveIndex === 1}>
                            <ScaleTable viewModel={viewModel.scaleTable} />
                        </Accordion.Content>

                    </Accordion>
                </Grid.Column>
            </Grid>
            <Modal
                closeIcon
                open={viewModel.modalOpened}
                onClose={() => viewModel.setModalOpened(false)}
                dimmer='blurring'
                header={viewModel.modalHeader}
                content={viewModel.modalContent}
            />
        </Segment>

    )
}

export default observer(Profile);