import React, { useContext, useEffect } from 'react';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Context } from '.';
import AppRoutes from './AppRoutes';
import AppWrapper from './components/AppWrapper';
import MainLayout from './components/MainLayout';
import './custom.css';

const App: FC = () => {

    const { store } = useContext(Context);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth();
        }
    }, []);

    return (
        <AppWrapper>
            <MainLayout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </MainLayout>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false }
                theme="dark" />
        </AppWrapper>)
}

export default App;

