import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect } from "react";
import { Button, Confirm, Modal, Table } from "semantic-ui-react";
import ScaleTableViewModel from "./ScaleTableViewModel";
import { Context } from "../..";


interface Props {
    viewModel: ScaleTableViewModel;
}

const ScaleTable: FC<Props> = ({ viewModel }) => {

    const { store } = useContext(Context);

    useEffect(() => {
        viewModel.fetchData();
    }, []);

    return (
        <>
            <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>№</Table.HeaderCell>
                        <Table.HeaderCell>Название</Table.HeaderCell>
                        {store.user.role === "Administrator" ?
                            <Table.HeaderCell>Активация</Table.HeaderCell>
                            :
                            ""
                        }
                        
                        <Table.HeaderCell colSpan='2'>Действия</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {viewModel.scales.map((scale, index) => (
                        <Table.Row key={scale.id}>
                            <Table.Cell collapsing>{index + 1}</Table.Cell>
                            <Table.Cell>{scale.name === '' ? scale.id : scale.name}</Table.Cell>
                            {store.user.role === "Administrator" ?
                                <Table.Cell collapsing>до 01.01.25</Table.Cell>
                                :
                                ""
                            }
                            <Table.Cell collapsing>
                                <Button
                                    primary
                                    icon='edit'
                                    onClick={() => viewModel.edit(scale.id)}
                                />

                                <Button
                                    color="red"
                                    icon='trash'
                                    onClick={() => viewModel.showConfirm(scale.id)}
                                />

                            </Table.Cell>
                        </Table.Row>
                    ))}
                    <Confirm
                        header='ВНИМАНИЕ!!!'
                        content='Данное действие НЕОБРАТИМО! Оно приведет к удалению ВСЕХ данных собраных этими весами!'
                        cancelButton='Отмена'
                        open={viewModel.openConfirm}
                        onConfirm={viewModel.deleteScale}
                        onCancel={viewModel.hideConfirm}/>
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan="4">
                            <Button
                                primary
                                fluid
                                icon='plus'
                                content="Добавить"
                                onClick={() => viewModel.add()}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <Modal
                closeIcon
                open={viewModel.openModal}
                onClose={() => viewModel.setOpenModal(false)}
                dimmer = 'blurring'>
                <Modal.Header>{viewModel.modalHeader}</Modal.Header>
                <Modal.Content>{viewModel.modalContent}</Modal.Content>
            </Modal>
        </>
    )
}

export default observer(ScaleTable);