import { FC } from "react"
import FirmwareVM from "./FirmwareVM"
import { observer } from "mobx-react-lite"
import { Form, Input, Button } from "semantic-ui-react"




interface Props {
    viewModel: FirmwareVM
}
const Firmware: FC<Props> = ({ viewModel }) => {
    return (
        <div>
            <Form>

                <Input
                    onChange={e => viewModel.setFormFile(e.target.files.item(0))}
                    type="file"
                />
                <Button primary content="Загрузить" onClick={() => viewModel.sendFile()} />
            </Form>
            <Form>

                <Input
                    onChange={e => viewModel.setFormFile(e.target.files.item(0))}
                    type="file"
                />
                <Button secondary content="Загрузить beta" onClick={() => viewModel.sendFileBeta()} />
            </Form>
        </div>
    )
}

export default observer(Firmware);