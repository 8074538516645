import { toast } from "react-toastify";
import $api from "../http";
import IErrorCode from "../models/IErrorCode";




export default class ErrorCodesService {

    static async getList(): Promise<IErrorCode[]> {
        //const currentToast = toast.loading('��������...');
        try {
            const response = await $api.get('/scaleerror/list');
            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: autoCloseDelay,
            //    isLoading: false
            //});

            return response.data;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose: 3000
            }
            )
            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            return [] as IErrorCode[];
        }
    }

    static async createCode(code: IErrorCode): Promise<void> {
        //const currentToast = toast.loading('��������...');
        try {
            const response = await $api.post('/scaleerror/create', {} = code);
            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: autoCloseDelay,
            //    isLoading: false
            //});

            return response.data;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose: 3000
            }
            )
            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            return ;
        }
    }

    static async updateCode(code: IErrorCode): Promise<void> {
        //const currentToast = toast.loading('��������...');
        try {
            const response = await $api.post('/scaleerror/update', {} = code);
            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: autoCloseDelay,
            //    isLoading: false
            //});

            return response.data;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose: 3000
            }
            )
            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            return;
        }
    }

    static async deleteCode(id: number): Promise<void> {
        //const currentToast = toast.loading('��������...');
        try {
            const response = await $api.post('/scaleerror/delete', `${id}`);
            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: autoCloseDelay,
            //    isLoading: false
            //});

            return response.data;
        }
        catch (e) {
            console.log(e.response?.data);
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose: 3000
            }
            )
            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            return;
        }
    }
}