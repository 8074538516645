import { makeAutoObservable } from "mobx";
import React from "react";
import { ReactNode } from "react";
import IErrorCode from "../../../../models/IErrorCode";
import ErrorCodesService from "../../../../services/ErrorCodesService";
import ViewModel from "../../../ViewModel";
import ErrorCodeAdd from "./ErrorCodeAdd/ErrorCodeAdd";
import ErrorCodeAddVM from "./ErrorCodeAdd/ErrorCodeAddVM";
import ErrorCodeEdit from "./ErrorCodeEdit/ErrorCodeEdit";
import ErrorCodeEditVM from "./ErrorCodeEdit/ErrorCodeEditVM";





export default class ErrorCodesVM implements ViewModel {

    onClose?: () => void;


    idToDelete: number;

    dataSet: IErrorCode[] = [];
    dataTable: IErrorCode[] = [];

    confirmEnabled: boolean;
    modalOpened: boolean;

    modalHeader: string;
    modalContent: ReactNode;




    constructor(onClose?: () => void) {
        this.onClose = onClose;
        makeAutoObservable(this);
    }

    


    



    setDataSet = (value: IErrorCode[]) => {
        this.dataSet = value;
    }

    setDataTable = (value: IErrorCode[]) => {
        this.dataTable = value;
    }

    setModalHeader = (value: string) => {
        this.modalHeader = value;
    }

    setModalContent = (value: ReactNode) => {
        this.modalContent = value;
    }

    setModalEnabled = (value: boolean) => {
        this.modalOpened = value;
    }


    addCode = () => {
        this.setModalHeader('Добавление кода');
        this.setModalContent(React.createElement(ErrorCodeAdd, { viewModel: new ErrorCodeAddVM(async () => {
            await this.fetchData();
            this.setModalEnabled(false);
        })
        }));
        this.setModalEnabled(true);
    }

    editCode = (value: IErrorCode) => {
        this.setModalHeader('Редактирование кода');
        this.setModalContent(React.createElement(ErrorCodeEdit, {
            viewModel: new ErrorCodeEditVM(value, async () => {
                await this.fetchData();
                this.setModalEnabled(false);
            })
        }));
        this.setModalEnabled(true);
    }

    deleteCode = async () => {
        await ErrorCodesService.deleteCode(this.idToDelete);
    }


    openConfirmDialog = (value: number) => {
        this.idToDelete = value;
        this.confirmEnabled = true;
    }

    closeConfirmDialog = () => {
        this.fetchData();
        this.confirmEnabled = false;
    }


    fetchData = async () => {
        let set = await ErrorCodesService.getList();
        this.setDataSet(set);
        this.setDataTable(set);
    }
}
