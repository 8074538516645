import React, { useEffect, FC } from 'react';
import { Table, Button, Modal, Form, Label} from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import ApiaryTableViewModel from './ApiaryTableViewModel';


interface Props {
    viewModel: ApiaryTableViewModel
}

const ApiaryTable: FC<Props> = ({ viewModel }) => {
    const {
        fetchData,
        apiaries,
        edit,
        add,
        deleteApiary,
        openModal,
        editMode,
        editName,
        setEditName,
        setOpenModal,
        save
    } = viewModel;

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>№</Table.HeaderCell>
                        <Table.HeaderCell>Название пасеки</Table.HeaderCell>
                        <Table.HeaderCell colSpan='2'>Действия</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {apiaries.map((apiary, index) => (
                        <Table.Row key={apiary.id}>
                            <Table.Cell collapsing>{index + 1}</Table.Cell>
                            <Table.Cell>{apiary.name}</Table.Cell>
                            <Table.Cell collapsing>
                                <Button
                                    primary
                                    icon='edit'
                                    onClick={() => edit(apiary.id, apiary.name)}
                                />

                            {/*</Table.Cell>*/}
                            {/*<Table.Cell collapsing>*/}
                                <Button
                                    color="red"
                                    icon='trash'
                                    onClick={() => deleteApiary(apiary.id)}
                                />

                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan="4">
                            <Button
                                fluid
                                primary
                                icon='plus'
                                content="Добавить"
                                onClick={() => add()}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <Modal
                closeIcon
                open={openModal}
                onClose={() => setOpenModal(false)}            >
                
                <Modal.Header>
                    {editMode ? 'Редактирование' : 'Добавление'} пасеки
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <Label>Название пасеки</Label>
                            <input
                                placeholder="Название пасеки"
                                value={editName}
                                onChange={(e) => setEditName(e.target.value)}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="black" onClick={() => setOpenModal(false)}>
                        Отмена
                    </Button>
                    <Button
                        positive
                        icon="checkmark"
                        labelPosition="right"
                        content="Сохранить"
                        onClick={() => save()}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default observer(ApiaryTable);