import { makeAutoObservable } from "mobx";
import ITransaction from "../../models/ITransaction";
import TransactionService from "../../services/TransactionService";




export default class TransactionViewViewModel {

    private transaction: ITransaction;
    private onClose: () => void;


    constructor() {
        makeAutoObservable(this);
    }

    setTransaction(value: ITransaction) {
        this.transaction = value;
    }

    get Transaction(): ITransaction {
        return this.transaction;
    }

    setOnClose(value: () => void) {
        this.onClose = value;
    }

    get OnClose(): () => void {
        return this.onClose;
    }

    async deleteTransaction(transactionId: number) {
        await TransactionService.deleteTransaction(transactionId);
        this.OnClose();
    }
}