import { makeAutoObservable } from "mobx";
import ViewModel from "../../ViewModel";
import AuthService from "../../../services/AuthService";
import { toast } from "react-toastify";




export default class SetPasswordVM implements ViewModel {

    onClose: () => void;

    id: number;
    password: string;
    passwordConfirm: string;

    constructor(onClose?: () => void, id?: number) {
        this.onClose = onClose;
        this.id = id;
        makeAutoObservable(this);
    }

    setPassword = (value: string) => {
        let result = value.replace(/[^0-9a-zA-Z]/g, '');
        this.password = result;
    }

    setPasswordConfirm = (value: string) => {
        let result = value.replace(/[^0-9a-zA-Z]/g, '');
        this.passwordConfirm = result;
    }

    onSubmit = () => {
        if (this.password === '') {
            toast.warning('Пароль не может быть пустым!')
            return;
        }
        if (this.password === this.passwordConfirm) {
            if (this.id === undefined) {
                AuthService.resetPassword(this.password);
            }
            else {
                AuthService.setPassword(this.id, this.password);
            }
            this.onClose();
        }
        else {
            toast.warning('Пароль и подтверждение не совпадают!');
        }
    }
}