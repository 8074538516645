import { toast } from "react-toastify";
import $api from "../http";
import IScale from "../models/IScale";
import IScaleConfig from "../models/IScaleConfig";

const successHideDelay = 300;


export default class ScaleService {
    

    static async getScales(): Promise<IScale[]> {
        //const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.get('/scale/list');

            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: successHideDelay,
            //    isLoading: false
            //});

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose:3000
            })

            return [] as IScale[];
        }
    }

    static async getList(): Promise<IScale[]> {
        //const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.get('/scale/');

            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: successHideDelay,
            //    isLoading: false
            //});

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose: 3000
            })

            return [] as IScale[];
        }
    }

    static async getScale(scaleId: number): Promise<IScale> {

        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post('/scale', `${scaleId}`);
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }

    static async updateScale(scale: IScale): Promise<boolean> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post('/scale/update', {} = scale);
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }

    static async getScaleConfig(scaleId: number): Promise<IScaleConfig> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post('/scale/config', `${scaleId}`);
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }

    static async updateScaleConfig(config: IScaleConfig): Promise<number> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post('/scale/config/update', {} = config);
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }

    static async changeScalePassword(scaleId: number): Promise<void> {
        //const currentToast = toast.loading('Ожидайте...');
        try {
            await $api.post('/scale/password/change', `${scaleId}`);

            //toast.update(currentToast, {
            //    type: toast.TYPE.SUCCESS,
            //    autoClose: successHideDelay,
            //    isLoading: false
            //});

        } catch (e) {
            console.log(e.response?.data);

            //toast.update(currentToast, {
            //    type: toast.TYPE.ERROR,
            //    autoClose: 3000,
            //    render: `${e.response?.status}: ${e.response?.data}`,
            //    isLoading: false
            //});
            toast.error(`${e.response?.status}: ${e.response?.data}`, {
                autoClose: 3000
            })
        }
    }

    static async createScale() {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post('/scale/create', new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000));
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }

    static async attachToUser(scaleId: number): Promise<string> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post("scale/attach", `${scaleId}`);
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }

    static async clearScale(scaleId: number): Promise<string> {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post('/scale/clear', `${scaleId}`);
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }

    static async deleteScale(scaleId: number) {
        const currentToast = toast.loading('Ожидайте...');
        try {
            const response = await $api.post('/scale/delete', `${scaleId}`);
            toast.update(currentToast, {
                type: 'success',
                autoClose: successHideDelay,
                isLoading: false
            });

            return response.data;
        } catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });

            return undefined;
        }
    }
}