import { FC, useEffect, useRef } from "react";
import { Table, Button, Confirm, Modal, Container, Sticky } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import ScalesVM from "./ScalesVM";

interface Props {
    viewModel: ScalesVM;
}

const Scales: FC<Props> = ({ viewModel }) => {

    let reference = useRef();

    useEffect(() => {
        viewModel.fetchData();
    }, []);

    return (
        <div ref={reference} style={{ height: 400, overflowY: "scroll" }}>
            <Table sortable celled compact style={{borderTopWidth: 0} }>
                <Table.Header >
                
                        <Table.Row style={{position: "sticky", top: 0, zIndex: 1} }>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Название</Table.HeaderCell>
                            <Table.HeaderCell>Пользователь</Table.HeaderCell>
                            <Table.HeaderCell>Активация</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>Действия</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                    {viewModel.dataTable.length === 0
                        ?
                        <Table.Row>
                            <Table.Cell colSpan="5" textAlign="center">
                                <span>Пусто</span>
                            </Table.Cell>

                        </Table.Row>

                        :
                        viewModel.dataTable.map((scale, index) => (
                            <Table.Row key={scale.id}>
                                <Table.Cell collapsing>{scale.id}</Table.Cell>
                                <Table.Cell>{scale.name}</Table.Cell>
                                <Table.Cell>{scale.user ? scale.user.name : ''}</Table.Cell>
                                <Table.Cell>{new Date(scale.expiryDate).toLocaleString('ru', { day: '2-digit', month: '2-digit', year: 'numeric' })}</Table.Cell>
                                <Table.Cell collapsing>
                                    {/*<Button*/}
                                    {/*    primary*/}
                                    {/*    icon='edit'*/}
                                    {/*    onClick={() => viewModel.editCode(code)}*/}
                                    {/*/>*/}

                                    <Button
                                        color="red"
                                        icon='trash'
                                        onClick={() => viewModel.openConfirmDialog(scale.id)}
                                    />

                                </Table.Cell>
                            </Table.Row>
                        ))}
                    <Confirm
                        header='!!!'
                        content='Уверен?!!'
                        cancelButton='Отмена'
                        open={viewModel.confirmEnabled}
                        onConfirm={async () => {
                            await viewModel.deleteScale();
                            await viewModel.fetchData();
                            await viewModel.closeConfirmDialog();
                        }}
                        onCancel={viewModel.closeConfirmDialog} />
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan="5">
                            <Button
                                primary
                                fluid
                                icon='plus'
                                content="Добавить"
                                onClick={async () => await viewModel.createScale()}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <Modal
                closeIcon
                onClose={() => viewModel.setModalEnabled(false)}
                open={viewModel.modalOpened}
                dimmer='blurring'>
                <Modal.Header>{viewModel.modalHeader}</Modal.Header>
                <Modal.Content>{viewModel.modalContent}</Modal.Content>
            </Modal>
        </div>
    )
}

export default observer(Scales);