import { FC } from "react";
import styled from "styled-components";



interface TriggerProps {
    expanded?: boolean;
}



const Container = styled.div`
    display: none;
    position: relative;
    width: 25px;
    height: 22px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    transform:${(props: TriggerProps) =>
        props.expanded ? 'rotate(360deg)' : 'rotate(0deg)'};    
    transition: transform .4s ease-out;
        span{
            position: absolute;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ffffe3;
            border-radius: 4px;
        }
        span:nth-of-type(1){
                top: 0;
                transform:${(props: TriggerProps) =>
        props.expanded ? 'translateY(9px) rotate(-45deg)' : 'translateY(0px) rotate(0deg)'};
            }
            span:nth-of-type(2){
                top: 9px;
                transform:${(props: TriggerProps) =>
        props.expanded ? 'translateY(0) rotate(45deg)' : 'translateY(0) rotate(0deg)'};
            }

            span:nth-of-type(3){
                bottom: 0;
                opacity:${(props: TriggerProps) =>
        props.expanded ? '0' : '1'};
            }

    @media(max-width: 500px){
        display: flex;
    }
}
`;




const Trigger: FC<TriggerProps> = (props) => {



    return (
        <Container {...props}>
            <span />
            <span />
            <span />
        </Container>
    )
}

export default Trigger;