import { makeAutoObservable } from "mobx";




export default class CancelButtonViewModel {
    private onClick: () => void;

    constructor() {
        makeAutoObservable(this);
    }

    setOnClick(func: () => void) {
        this.onClick = func;
    }

    get OnClick(): () => void {
        return this.onClick;
    }
}