import { makeAutoObservable } from "mobx";
import React from "react";
import { ReactNode } from "react";
import IScale from "../../../../models/IScale";
import ErrorCodesService from "../../../../services/ErrorCodesService";
import ViewModel from "../../../ViewModel";
import ScaleService from "../../../../services/ScaleService";





export default class ScalesVM implements ViewModel {

    onClose?: () => void;

    constructor(onClose?: () => void) {
        this.onClose = onClose;
        makeAutoObservable(this);
    }

    idToDelete: number;

    dataSet: IScale[] = [];
    dataTable: IScale[] = [];

    confirmEnabled: boolean;
    modalOpened: boolean;

    modalHeader: string;
    modalContent: ReactNode;






    setDataSet = (value: IScale[]) => {
        this.dataSet = value;
    }

    setDataTable = (value: IScale[]) => {
        this.dataTable = value;
    }

    setModalHeader = (value: string) => {
        this.modalHeader = value;
    }

    setModalContent = (value: ReactNode) => {
        this.modalContent = value;
    }

    setModalEnabled = (value: boolean) => {
        this.modalOpened = value;
    }


    //addCode = () => {
    //    this.setModalHeader('Добавление кода');
    //    this.setModalContent(React.createElement(ErrorCodeAdd, {
    //        viewModel: new ErrorCodeAddVM(async () => {
    //            await this.fetchData();
    //            this.setModalEnabled(false);
    //        })
    //    }));
    //    this.setModalEnabled(true);
    //}

    //editCode = (value: IScale) => {
    //    this.setModalHeader('Редактирование кода');
    //    this.setModalContent(React.createElement(ErrorCodeEdit, {
    //        viewModel: new ErrorCodeEditVM(value, async () => {
    //            await this.fetchData();
    //            this.setModalEnabled(false);
    //        })
    //    }));
    //    this.setModalEnabled(true);
    //}
    createScale = async () => {
        await ScaleService.createScale();
        await this.fetchData();
    }

    deleteScale = async () => {
        await ScaleService.deleteScale(this.idToDelete);
    }


    openConfirmDialog = (value: number) => {
        this.idToDelete = value;
        this.confirmEnabled = true;
    }

    closeConfirmDialog = () => {
        this.fetchData();
        this.confirmEnabled = false;
    }


    fetchData = async () => {
        let set = await ScaleService.getList();
        this.setDataSet(set);
        this.setDataTable(set);
    }
}
