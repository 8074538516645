import React, { PropsWithChildren } from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import LoginDisplay from './LoginDisplay';
import Logo from './Logo';
import NavMenu from './NavMenu';



const Container = styled.div`
    min-height: 50px;
    max-height: 100px;
    width: 100%;
    display: flex;
    flex-grow: 1;
    color: #ffffe3;
`;

const Buffer = styled.div`
    display: flex;
    flex-grow: 1;
    
    @media(max-width: 500px){
        display: none;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    min-width: 90%;
    max-width: 1200px;
`




const Header: FC = () => {
    return (
        <Container>
            <Buffer />
            <Wrapper>
                <Logo />
                <NavMenu />
                <LoginDisplay />
            </Wrapper>
            <Buffer />
        </Container>
    )
}

export default Header;