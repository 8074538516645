import React from 'react';
import { FC } from 'react';
import { Context } from '../index';
import { observer } from "mobx-react-lite";
import styled from 'styled-components';
import { IconButton } from './styledElements/styledButtons';
import { useNavigate } from 'react-router';

const Scales: FC = () => {

    const navigate = useNavigate();

    const { store } = React.useContext(Context);

    const Content = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        
        min-height: 400px;
        width: 100%;

        padding: 10px;
    `


    return (
        <Content>
            {store.isAuthenticate ?
                <IconButton onClick={() => navigate('/scales/user-charts')}>График</IconButton>
                :
                ''}
            <IconButton onClick={() => navigate('/scales/demo')}>Demo</IconButton>
        </Content>
    );



}
export default observer(Scales);
