import { toast } from "react-toastify";
import $api from "../http";


const autoCloseDelay = 300;

export default class FsService {

    static async upload(file: File) {

        const currentToast = toast.loading('Ожидайте...');

        let formData = new FormData();

        formData.append("file", file);

        try {
            await $api.post("/fs/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }

        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }

    static async uploadBeta(file: File) {

        const currentToast = toast.loading('Ожидайте...');

        let formData = new FormData();

        formData.append("file", file);

        try {
            await $api.post("/fs/upload-beta", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            toast.update(currentToast, {
                type: 'success',
                autoClose: autoCloseDelay,
                isLoading: false
            });

            return true;
        }

        catch (e) {
            console.log(e.response?.data);
            toast.update(currentToast, {
                type: 'error',
                autoClose: 3000,
                render: `${e.response?.status}: ${e.response?.data}`,
                isLoading: false
            });
            return false;
        }
    }
}