import styled, { keyframes } from 'styled-components';



interface Locked {
    locked: boolean;
    active: boolean;
    originTop?: boolean;
}


export const Content = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
        width: 100%;
        max-width: 1000px;

        padding: 10px;
    `

export const Shaded = styled.div`
        transform: scaleY(${(props: Locked) => props.locked ? '0' : '1'});
        transform-origin: ${(props: Locked) => props.originTop ? 'top' : 'bottom' };
        display: ${(props: Locked) => props.active ? 'flex' : 'none'};
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        color: #ffffe3;
        border-radius: 10px;
        background-color: #000000a8;
        box-shadow: 0px 0px 5px 5px #000000a8;
        transition: transform 3s ease;

    `
export const Title = styled.div`
        font-weight:bold;
        font-size:35px;
        text-align:center;
    `

export const Article = styled.div`
        font-size:24px;
        margin: 10px 0;
        text-align:center;
        align-items: center;
    `
export const Jumping = keyframes`
        from{ transform:translateY(0);}
        50%{transform:translateY(-10px);}
        to{ transform: translateY(0);}
    `

export const Arrow = styled.svg`
        position: absolute;
        bottom:90px;
        fill: #fff;
        cursor: pointer;
        animation-duration: 1.3s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-name:${Jumping};
    `

export const Buffer = styled.div`
    height: 100vh;
`