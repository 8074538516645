import { FC } from "react";
import SetChatIdVM from "./SetChatIdVM";
import { observer } from "mobx-react-lite";
import { Form, Segment } from "semantic-ui-react";

interface Props {
    viewModel: SetChatIdVM;
}


const SetPassword: FC<Props> = ({ viewModel }) => {

    return (
        <Segment>
            <Form>
                <Form.Input
                    label='Номер из бота:'
                    type='text'
                    value={viewModel.chatId}
                    onChange={e => viewModel.setChatId(e.target.value)}>
                </Form.Input>
            </Form>
            <Form.Group inline>
                <Form.Button
                    color='black'
                    content='Отмена'
                    onClick={() => viewModel.onClose()}
                />
                <Form.Button positive
                    icon="checkmark"
                    labelPosition="right"
                    content="Сохранить"
                    onClick={() => { viewModel.onSubmit() }}
                />
            </Form.Group>
        </Segment>
    )
}

export default observer(SetPassword);