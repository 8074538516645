import React, { PropsWithChildren } from 'react';
import { FC } from 'react';
import styled from 'styled-components';


const StyledAppWrapper = styled.div`
display:flex;
width:100vw;
min-height:100vh;
background:black;
justify-content: center;
flex-direction: column;
color: white;
`

const AppWrapper: FC<PropsWithChildren> = ({children}) => {
    return (
        <StyledAppWrapper>
            {children}
        </StyledAppWrapper>
        );
}

export default AppWrapper