import { makeAutoObservable } from "mobx";
import IErrorCode from "../../../../../models/IErrorCode";
import ErrorCodesService from "../../../../../services/ErrorCodesService";
import ViewModel from "../../../../ViewModel";




export default class ErrorCodeEditVM implements ViewModel {

    onClose?: () => void;

    defaultCode: IErrorCode;
    id: number;
    name: string;
    description: string;



    constructor(code: IErrorCode, onClose?: () => void) {
        this.defaultCode = code;
        this.onClose = onClose;

        this.id = code.id;
        this.name = code.name;
        this.description = code.description;

        makeAutoObservable(this);
    }



    setId = (value: string) => {
        let result = value.replace(/[^0-9]/g, '');
        this.id = +result;
    }

    setName = (value: string) => {
        this.name = value;
    }

    setDescription = (value: string) => {
        this.description = value;
    }

    saveCode = () => {
        if (this.id !== this.defaultCode.id ||
            this.name !== this.defaultCode.name ||
            this.description !== this.defaultCode.description) {
            let code: IErrorCode = { id: this.id, name: this.name, description: this.description };
            ErrorCodesService.updateCode(code);
        }
        this.onClose();
    }
}