import { FC, useEffect } from "react";
import ErrorCodesVM from "./ErrorCodesVM";
import { Table, Button, Confirm, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

interface Props {
    viewModel: ErrorCodesVM;
}

const ErrorCodes: FC<Props> = ({ viewModel }) => {

    useEffect(() => {
        viewModel.fetchData();
    }, []);

    return (
        <>
            <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Название</Table.HeaderCell>
                        <Table.HeaderCell>Описание</Table.HeaderCell>
                        <Table.HeaderCell colSpan='2'>Действия</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {viewModel.dataTable.length === 0
                        ?
                        <Table.Row>
                            <Table.Cell colSpan="5" textAlign="center">
                                <span>Пусто</span>
                            </Table.Cell>

                        </Table.Row>

                        :
                        viewModel.dataTable.map((code, index) => (
                            <Table.Row key={code.id}>
                                <Table.Cell collapsing>{code.id}</Table.Cell>
                                <Table.Cell>{code.name}</Table.Cell>
                                <Table.Cell>{code.description}</Table.Cell>
                                <Table.Cell collapsing>
                                    <Button
                                        primary
                                        icon='edit'
                                        onClick={() => viewModel.editCode(code)}
                                    />

                                    <Button
                                        color="red"
                                        icon='trash'
                                        onClick={() => viewModel.openConfirmDialog(code.id)}
                                    />

                                </Table.Cell>
                            </Table.Row>
                        ))}
                    <Confirm
                        header='!!!'
                        content='Уверен?!!'
                        cancelButton='Отмена'
                        open={viewModel.confirmEnabled}
                        onConfirm={() => {
                            viewModel.deleteCode();
                            viewModel.closeConfirmDialog();
                        }}
                        onCancel={viewModel.closeConfirmDialog} />
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan="5">
                            <Button
                                primary
                                fluid
                                icon='plus'
                                content="Добавить"
                                onClick={() => viewModel.addCode()}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <Modal
                closeIcon
                onClose={() => viewModel.setModalEnabled(false)}
                open={viewModel.modalOpened}
                dimmer='blurring'>
                <Modal.Header>{viewModel.modalHeader}</Modal.Header>
                <Modal.Content>{viewModel.modalContent}</Modal.Content>
            </Modal>
        </>
    )
}

export default observer(ErrorCodes);