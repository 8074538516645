import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Button, Confirm, Segment, Table, Modal } from "semantic-ui-react";
import CustomerVM from "./CustomersVM";



interface Props {
    viewModel: CustomerVM;
}


const Customers: FC<Props> = ({ viewModel }) => {

    useEffect(() => {
        viewModel.fetchData();
    }, []);

    return (
        <Segment>
            <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Имя</Table.HeaderCell>
                        <Table.HeaderCell>Весы</Table.HeaderCell>
                        <Table.HeaderCell>Почта</Table.HeaderCell>
                        <Table.HeaderCell>Роль</Table.HeaderCell>
                        <Table.HeaderCell colSpan='2'>Действия</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {viewModel.dataTable.length === 0
                        ?
                        <span>'Empty'</span>
                        :
                        viewModel.dataTable.map((customer, index) => (
                            <Table.Row key={customer.id}>
                                <Table.Cell collapsing>{customer.id}</Table.Cell>
                                <Table.Cell>{customer.name}</Table.Cell>
                                <Table.Cell>{customer.scales.length}</Table.Cell>
                                <Table.Cell>{customer.email}</Table.Cell>
                                <Table.Cell>{customer.role}</Table.Cell>
                                <Table.Cell collapsing>
                                    <Button
                                        primary
                                        icon='edit'
                                        onClick={() => viewModel.openEditor(customer.id)}
                                    />

                                    <Button
                                        color="red"
                                        icon='trash'
                                        onClick={() => viewModel.openConfirmDialog(customer.id)}
                                    />

                                </Table.Cell>
                            </Table.Row>
                        ))}
                    <Confirm
                        header='!!!'
                        content='Уверен?!!'
                        cancelButton='Отмена'
                        open={viewModel.confirmEnabled}
                        onConfirm={() => {
                            viewModel.deleteCustomer();
                            viewModel.closeConfirmDialog();
                        }}
                        onCancel={viewModel.closeConfirmDialog} />
                </Table.Body>
               
            </Table>

            <Modal
                closeIcon
                onClose={() => viewModel.setModalOpened(false)}
                open={viewModel.modalOpened}
                dimmer='blurring'>
                <Modal.Header>{viewModel.modalHeader}</Modal.Header>
                <Modal.Content>{viewModel.modalContent}</Modal.Content>
            </Modal>

        </Segment>
    );
}

export default observer(Customers);