import React, { useRef } from 'react';
import { FC, useContext } from 'react';
import { Context } from '..';
import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Panel } from './styledElements/styledContainers';
import { PrimaryButton } from './styledElements/styledButtons';
import Modal from './Containers/Modal/Modal';
import LoginFormViewModel from './LoginForm/LoginFormViewModel';



interface Expandable {
    expanded: boolean;
}



const Form = styled(Panel)`
    width:50%;
    width: 165px;
    padding: 0;
`
const HeaderArea = styled.div`
    position: relative;
    display:flex;
    width:100%;
    min-height: 30px;
    border-radius:10px 10px 0px 0px;
`
const SwitcherArea = styled(HeaderArea)`
    border-radius:0px 0px 10px 10px;
`
const Header = styled.div`
    position: absolute;
    display: ${(props: Expandable) => props.expanded ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    background: #f9b863;
    width:100%;
    height: 100%;
    border-radius:10px 10px 0px 0px;
`
const Switcher = styled(Header)`
    cursor: pointer;
    background: #bca0ff;
    border-radius:0px 0px 10px 10px;
`
const ContentArea = styled.div`
    display: ${(props: Expandable) => props.expanded ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    min-width: 165px;
    width: 100%;
    height: 100%;
    padding: 10px;

    input[type="text"], input[type="password"]{
        width: 145px;
    }

    input[type='checkbox']{
        margin-right: 5px;
    }
`
const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    margin:10px 0px 10px 0px;
    
`
const InlineButton = styled.button`
    background: none;
    padding: 0px;
    margin: 0px;
    text-decoration: underline dashed #ff0000;
    font-family: inherit;
`




const LoginForm: FC = () => {
    const refLogUsername = useRef(null);
    const refLogPassword = useRef(null);
    const refRegUsername = useRef(null);
    const refRegPassword = useRef(null);
    const refRegPasswordConfirm = useRef(null);
    const refRegEmail = useRef(null);

    const { store } = useContext(Context);
    LoginFormViewModel.setStore(store);
    LoginFormViewModel.setNavigate(useNavigate());


    return (
        <Form direction='column'>
            <HeaderArea>
                <Header expanded={LoginFormViewModel.loginMode}>
                    Вход
                </Header>
                <Header expanded={!LoginFormViewModel.loginMode}>
                    Регистрация
                </Header>
            </HeaderArea>

            <ContentArea expanded={LoginFormViewModel.loginMode}>
                <FormRow>
                    <input
                        ref={refLogUsername}
                        onChange={e => LoginFormViewModel.setUsername(e.target.value)}
                        value={LoginFormViewModel.username}
                        type='text'
                        placeholder='Логин'
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                LoginFormViewModel.setUsername(e.currentTarget.value);
                                if (LoginFormViewModel.username === '') return;
                                refLogPassword.current.focus();
                            }
                        }}
                    />
                </FormRow>

                <FormRow>
                    <input
                        ref={refLogPassword}
                        onChange={e => LoginFormViewModel.setPassword(e.target.value)}
                        value={LoginFormViewModel.password}
                        type='password'
                        placeholder='Пароль'
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                LoginFormViewModel.setPassword(e.currentTarget.value);
                                LoginFormViewModel.login();
                            }
                        }}
                    />
                </FormRow>

                <FormRow>
                    <PrimaryButton onClick={LoginFormViewModel.login}>Логин</PrimaryButton>
                </FormRow>

            </ContentArea>

            <ContentArea expanded={!LoginFormViewModel.loginMode}>
                <FormRow>
                    <input
                        ref={refRegUsername}
                        onChange={e => LoginFormViewModel.setUsername(e.target.value)}
                        value={LoginFormViewModel.username}
                        type='text'
                        placeholder='Логин'
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                LoginFormViewModel.setUsername(e.currentTarget.value);
                                refRegEmail.current.focus();
                            }
                        }}
                    />
                </FormRow>

                <FormRow>
                    <input
                        ref={refRegEmail}
                        onChange={e => LoginFormViewModel.setEmail(e.target.value)}
                        value={LoginFormViewModel.email}
                        type='text'
                        placeholder='Почта'
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                LoginFormViewModel.setUsername(e.currentTarget.value);
                                refRegPassword.current.focus();
                            }
                        }}
                    />
                </FormRow>

                <FormRow>
                    <input
                        ref={refRegPassword}
                        onChange={e => LoginFormViewModel.setPassword(e.target.value)}
                        value={LoginFormViewModel.password}
                        type='password'
                        placeholder='Пароль'
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                LoginFormViewModel.setPassword(e.currentTarget.value);
                                LoginFormViewModel.register();
                            }
                        }}
                    />
                </FormRow>

                <FormRow>
                    <input
                        ref={refRegPasswordConfirm}
                        onChange={e => LoginFormViewModel.setPasswordConfirm(e.target.value)}
                        value={LoginFormViewModel.passwordConfirm}
                        type='password'
                        placeholder='Подтверждение пароля'
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                LoginFormViewModel.setPasswordConfirm(e.currentTarget.value);
                                LoginFormViewModel.register();
                            }
                        }}
                    />
                </FormRow>

                <FormRow>
                    <div>
                        <input
                            onChange={e => LoginFormViewModel.setGarConfirm(e.target.checked)}
                            checked={LoginFormViewModel.garConfirm}
                            type='checkbox'
                        />
                    </div>

                    <div><label> Ознакомлен с <InlineButton onClick={LoginFormViewModel.openModal}>отказом от ответственности</InlineButton>.</label></div>
                </FormRow>

                <FormRow>
                    <input
                        onChange={e => LoginFormViewModel.setDataStoreConfirm(e.target.checked)}
                        checked={LoginFormViewModel.dataStoreConfirm}
                        type='checkbox'
                    />
                    <label>Согласен на хранение и обработку персональных данных.</label>
                </FormRow>

                <FormRow>
                    <PrimaryButton onClick={LoginFormViewModel.register}>Зарегистрировать</PrimaryButton>
                </FormRow>

            </ContentArea>


            <SwitcherArea>
                <Switcher expanded={!LoginFormViewModel.loginMode} onClick={() => LoginFormViewModel.setLoginMode(true)}>
                    Есть учетная запись?
                </Switcher>
                <Switcher expanded={LoginFormViewModel.loginMode} onClick={() => LoginFormViewModel.setLoginMode(false)}>
                    Нет учетной записи?
                </Switcher>
            </SwitcherArea>

            <Modal viewModel={LoginFormViewModel.modal}></Modal>
        </Form>
    );
}
export default observer(LoginForm);
