import { transaction } from "mobx";
import $api from "../http";
import IScale from "../models/IScale";
import ITransaction from "../models/ITransaction";




export default class TransactionService {

    static async getTransactions(scaleId: number, begin: Date, end: Date): Promise<ITransaction[]> {
        return (await $api.post("/scale/transaction/list", {
            "scaleId": scaleId,
            "beginInterval": begin,
            "endInterval": end
        })).data
    }

    static async getData(userName: string, start: Date, end: Date): Promise<IScale[]> {
        return (await $api.post("/scale/t-data", {
            'userName': userName,
            'beginInterval': start,
            'endInterval': end,
        })).data;
    }

    static async deleteTransaction(transactionId: number): Promise<boolean> {
        return (await (await $api.post('/scale/transaction/delete', `${transactionId}`)).data);
    }
}