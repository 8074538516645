import styled from "styled-components";



interface ButtonProps {
    maxHeight?: string;
    maxWidth?: string;
    size?: string;
    fill?: string;
    position?: string;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    border?: string;
}

export const RoundButton = styled.button<ButtonProps>`
  background-color: ${props => props.fill || 'red'};
  border-radius: 50%;
  max-width: ${(props) => props.maxWidth || 'auto'};
  width: ${(props) => props.size || 'auto'};
  height: ${(props) => props.size || 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => props.border || 'none'};
  position: ${(props) => props.position || 'relative'};;
  top: ${(props) => props.top || 'auto'};
  right: ${(props) => props.right || 'auto'};
  bottom: ${(props) => props.bottom || 'auto'};
  left: ${(props) => props.left || 'auto'};
`;


export const PrimaryButton = styled.button`
    color: #ffffe3;
    cursor: pointer;
    background-color: #f9b863;
    padding: 5px;
    border: 1px solid #f9b863;
    border-radius: 5px;
        
        :hover{
            background-color: #ffc06d;
        }
        
        :active{
            border: 1px solid #00dbef;
            
`

export const IconButton = styled.button`
    min-height: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: #ffffe3;
    box-shadow:5px 5px 4px 0;
    transition: 0.2s;
    
    :hover{
        transform: scale(1.2);
        transform-origin: top left;
        box-shadow: 10px 10px 7px 0;
    }
    
    :active{
        transform: scale(1);
        transform-origin: top ;
        box-shadow: 5px 5px 4px 0;
    }
`
