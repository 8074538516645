import $api from "../http";
import IUser from "../models/IUser";



export default class UserService {
    static async getUser(name: string): Promise<IUser>{
        return (await $api.post('/user', `"${name}"`)).data;
    }

    static async getList(): Promise<IUser[]> {
        return (await $api.get('/user/list')).data;
    }

    static async updateUser(user: IUser): Promise<boolean> {
        return (await (await $api.post('/user/update', {} = user)).data);
    }

    static async deleteUser(id: number): Promise<boolean> {
        return (await (await $api.post('/user/delete', `${id}`)).data);
    }
}