


import React from 'react';
import _ from 'lodash';
import { makeAutoObservable } from "mobx";
import IScale from "../../models/IScale";
import TransactionService from "../../services/TransactionService";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";
import ModalViewModel from '../Containers/Modal/ModalViewModel';
import TooltipOptions from '../TooltipOptions/TooltipOptions';
import TooltipOptionsViewModel from '../TooltipOptions/TooltipOptionsViewModel';
import IUser from '../../models/IUser';
import ITooltipConfig from '../../models/ITooltipConfig';
import TooltipConfigService from '../../services/TooltipConfigService';
import ITransaction from '../../models/ITransaction';
import TransactionViewViewModel from '../../Views/TransactionView/TransactionViewViewModel';
import TransactionView from '../../Views/TransactionView/TransactionView';
import { DropdownItemProps } from 'semantic-ui-react';
import { ListAutoDispose } from '@amcharts/amcharts5/.internal/core/util/List';
import ChartYaxis from './Chart/ChartYAxis/ChartYAxis';



export default class ChartViewModel {
    public dataSet: IScale[] = [];
    public filtredDataSet: IScale[] = [];
    public startInterval: Date = new Date(new Date(Date.now()).setHours(3, 0, 0, 0));
    public endInterval: Date = new Date(this.startInterval.getTime() + 1000 * 60 * 60 * 24);
    public category: string = 'relativeWeight';
    public categoryOptions: DropdownItemProps[] = [
        {
            key: 'weight', text: 'Вес ⚖', value: 'weight'
        },
        {
            key: 'relW', text: 'Привес ⚖Δ', value: 'relativeWeight'
        },
        {
            key: 'airT', text: 'Т. воздуха 🌡в', value: 'airTemperature'
        },
        {
            key: 'hum', text: 'Влажность 💧', value: 'humidity'
        },
        {
            key: 'press', text: 'Давление ⏲', value: 'pressure'
        },
        {
            key: 'sysT', text: 'Т. системы 🌡с', value: 'systemTemperature'
        },
        {
            key: 'charge', text: 'Заряд 🔋', value: 'charge'
        },
        {
            key: 'sigLev', text: 'Сигнал 📡', value: 'signalLevel'
        },
    ];
    public aditionalColors: string[] = [
        '#000000',
        '#505050',
        '#808080',
        '#999999'
    ];
    public selectedScaleName: string = 'все';
    public listOfYAxes: ChartYaxis[] = [];

    private tooltipConfig: ITooltipConfig;
    private currentModalContent: any;
    private currentModal: ModalViewModel = new ModalViewModel();
    private user: IUser;
    private root: am5.Root;
    private chart: am5xy.XYChart;

    private currentYAxis: ChartYaxis;
    private currentYLabel: am5.Label;

    private xAxis: am5xy.DateAxis<am5xy.AxisRenderer>;
    private legend: am5.Legend;


    constructor() {
        makeAutoObservable(this);
    }

    setTooltipConfig(value: ITooltipConfig) {
        this.tooltipConfig = value;
    }

    get TooltipConfig(): ITooltipConfig {
        return this.tooltipConfig;
    }

    setUser(value: IUser) {
        this.user = value;
    }


    setDataSet(value: IScale[]) {
        this.dataSet = value;
    }

    get DataSet(): IScale[] {
        return this.dataSet;
    }

    fetchData = async (): Promise<void> => {
        this.setDataSet(await TransactionService.getData(this.user.name, this.startInterval, this.endInterval));

        for (let item of this.DataSet) {
            if (item.transactions.length === 0) continue;
            item.transactions.sort((a, b) => (a.time < b.time ? -1 : 1));
            const baseWeight = item.transactions[0].weight;
            for (let tItem of item.transactions) {
                tItem.relativeWeight = tItem.weight - baseWeight;
            }
        }
        this.setFiltredDataSet();
    };

    setStartInterval = (date: Date): void => {
        this.startInterval = date;
    };

    setEndInterval = (date: Date): void => {
        this.endInterval = date;
    };

    setCategory = (category: string): void => {
        this.category = category;
        if (this.currentYAxis !== undefined) {
            this.currentYAxis.category = category;
        }
        this.changeSeriesCategory();
    };

    //Сегодня
    setToday = async () => {
        this.setStartInterval(new Date(new Date(Date.now()).setHours(3, 0, 0, 0)));
        this.setEndInterval(new Date(new Date(Date.now()).setHours(27, 0, 0, 0)));
        await this.fetchData();
        this.refreshSeries();
    };

    //Вчера
    setYesterday = async () => {
        this.setStartInterval(new Date(new Date(Date.now()).setHours(-24 + 3, 0, 0, 0)));
        this.setEndInterval(new Date(new Date(Date.now()).setHours(3, 0, 0, 0)));
        await this.fetchData();
        this.refreshSeries();
    };

    // За два дня
    setDayBeforeYesterday = async () => {
        this.setStartInterval(new Date(new Date(Date.now()).setHours(-24 + 3, 0, 0, 0)));
        this.setEndInterval(new Date(new Date(Date.now()).setHours(27, 0, 0, 0)));
        await this.fetchData();
        this.refreshSeries();
    };

    setLastWeek = async () => {
        this.setStartInterval(new Date(new Date(Date.now()).setHours(-24 * 6 + 3, 0, 0, 0)));
        this.setEndInterval(new Date(new Date(Date.now()).setHours(27, 0, 0, 0)));
        await this.fetchData();
        this.refreshSeries();
    };

    setSelectedScaleName = (name: string) => {
        this.selectedScaleName = name;
        this.setFiltredDataSet();
        if (name === 'все') {
            this.chart.yAxes.clear();
            this.createYAxis();
        }
        this.refreshSeries();
    };

    setFiltredDataSet = () => {
        if (this.selectedScaleName !== 'все') {
            this.filtredDataSet = this.dataSet.filter(scale => scale.name === this.selectedScaleName);
        }
        else {
            this.filtredDataSet = this.dataSet;
        }
    };

    confirmPeriod = async () => {
        await this.fetchData();
        this.refreshSeries();
    };


    get selectedStartInterval(): Date {
        return this.startInterval;
    }

    get listOfScales(): DropdownItemProps[] {
        if (this.dataSet.length !== 0) {
            let result: DropdownItemProps[] = [];
            result.push({ key: 'emp', text: 'Все', value: 'все' });
            for (var item of this.dataSet) {
                result.push({ key: item.name, text: item.name, value: item.name });
            }
            return result;
        }
        return [];
    }

    setListOfYAxes = (value: ListAutoDispose<am5xy.Axis<am5xy.AxisRenderer>>) => {
        let result: ChartYaxis[] = [] as ChartYaxis[];
        value.each((axe) => result.push(axe as ChartYaxis));
        this.listOfYAxes = result;
    };

    setCurrentYAxis = (value: ChartYaxis) => {
        if (value !== undefined) {
            this.currentYAxis = value;
            this.currentYLabel = value.children.getIndex(0) as am5.Label;
            this.setCategory(value.category);
            console.log(this.category);
        }
    };

    setCurrentYLabel = (value: am5.Label) => {
        this.currentYLabel = value;
    };

    createChart = () => {
        //if (this.root !== null && this.root !== undefined) {
        //    this.root.dispose();
        //}
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root.dom.id === "chartdiv") {
                root.dispose();
            }
        });
        this.root = am5.Root.new("chartdiv");
        this.legend = am5.Legend.new(this.root, {
            y: am5.p100,
            centerY: am5.p100
        });



        this.root.setThemes([
            am5themes_Animated.new(this.root)
        ]);
        this.root.locale = am5locales_ru_RU;
        this.chart = this.root.container.children.push(
            am5xy.XYChart.new(this.root, {
                paddingTop: 50,
                paddingLeft: 0,
                paddingBottom: 0,
                paddingRight: 0,
                panY: false,
                panX: false,
                wheelY: "zoomX",
                pinchZoomX: true,
                layout: this.root.verticalLayout,
                maxTooltipDistance: 10
            })
        );
        this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
        this.chart.topAxesContainer.children.push(this.legend);
    };

    createYAxis = () => {
        if (this.chart !== undefined) {

            let newAxis = this.chart.yAxes.push(
                ChartYaxis.new(this.root, {
                    renderer: am5xy.AxisRendererY.new(this.root, {
                        minGridDistance: 20,
                    })
                })
            );
            newAxis.set('tooltip', am5.Tooltip.new(this.root, {}));
            newAxis.category = this.category;


            newAxis.axisHeader.get('background').setAll({
                fillOpacity: 0
            });
            let newYLabel = newAxis.children.unshift(am5.Label.new(this.root, {
                rotation: 0,
                y: -50,
                x: 95,
                centerX: am5.p100,
            }));
            this.setCurrentYAxis(newAxis);
            if (this.chart.yAxes.length > 1) {
                this.currentYAxis.color = this.randomColor();
            }
            this.setCurrentYLabel(newYLabel);
            this.setYLabelText();
            this.setListOfYAxes(this.chart.yAxes);
        }
    };

    createXAxis = () => {
        if (this.chart !== undefined) {
            this.xAxis = this.chart.xAxes.push(
                am5xy.DateAxis.new(this.root, {
                    baseInterval: {
                        timeUnit: 'minute',
                        count: 10
                    },
                    renderer: am5xy.AxisRendererX.new(this.root, {
                        minGridDistance: 12,
                    }),
                })
            );
            this.xAxis.get('renderer').labels.template.setAll({
                rotation: 50,
                fontSize: 12,
            });
            this.xAxis.set('tooltip', am5.Tooltip.new(this.root, {}));
        }
    };



    createSeries = (scale: IScale, root: am5.Root, color?: string) => {
        if (scale.transactions.length === 0) return;
        const series = this.chart.series.push(
            am5xy.SmoothedXLineSeries.new(root, {
                name: scale.name,
                xAxis: this.xAxis,
                yAxis: this.currentYAxis,
                valueYField: this.currentYAxis.category,
                valueXField: "time",
                idField: "id",
                minBulletDistance: 10,
                tension: 0.7,
            })
        );
        if (scale.color !== '' && this.chart.yAxes.length < 2) {
            series.set('stroke', am5.color(scale.color));
            series.set('fill', am5.color(scale.color));
            this.currentYAxis.color = scale.color;
        }
        else {
            if (this.currentYAxis.color !== '') {
                series.set('stroke', am5.color(this.currentYAxis.color));
                series.set('fill', am5.color(this.currentYAxis.color));
            }
            else {
                this.currentYAxis.color = series.get('stroke').toString();
            }
        }
        series.strokes.template.setAll({ strokeWidth: 3, });

        //Создание диамазонов для мин/макс.
        if (this.filtredDataSet.length < 2 && this.listOfYAxes.length < 2) {


            series.axisRanges.clear();

            //Цвета для нижнего и верхнего диапазонов.
            let green = am5.color('#98FB98'); //Зеленый.
            let red = am5.color('#F08080'); //Красный.

            let minValue = this.getProperty(scale.transactions.reduce((acc, curr) => this.getProperty(acc, this.category) < this.getProperty(curr, this.category)
                ? acc
                : curr), this.category) as number; // Минимальное значение.
            let maxValue = this.getProperty(scale.transactions.reduce((acc, curr) => this.getProperty(acc, this.category) > this.getProperty(curr, this.category)
                ? acc
                : curr), this.category) as number; // Максимальное значение.


            // Верхний диапазон.
            let hiRangeDataItem = this.currentYAxis.makeDataItem({
                value: 0,
                endValue: maxValue
            });

            let hiRange = series.createAxisRange(hiRangeDataItem);

            hiRange.fills.template.setAll({
                fill: green,
                fillOpacity: 0.2,
                visible: true
            });

            hiRange.axisDataItem.get('label').setAll({
                text: '' + maxValue.toFixed(2),
                location: 1,
                layer: 999,
                background: am5.RoundedRectangle.new(this.root, {
                    fill: green,
                    opacity: 1
                }),
                visible: true
            });

            hiRange.axisDataItem.get('grid').setAll({
                stroke: green,
                strokeOpacity: 1,
                strokeWidth: 3,
                location: 1,
                visible: true
            });


            // Нижний диапазон.
            let lowRangeDataItem = this.currentYAxis.makeDataItem({
                value: 0,
                endValue: minValue
            });

            let lowRange = series.createAxisRange(lowRangeDataItem);

            lowRange.fills.template.setAll({
                fill: red,
                fillOpacity: 0.2,
                visible: true
            });

            lowRange.axisDataItem.get('label').setAll({
                text: '' + minValue.toFixed(2),
                location: 1,
                layer: 999,
                background: am5.RoundedRectangle.new(root, {
                    fill: red,
                    opacity: 1
                }),
                visible: true
            });

            lowRange.axisDataItem.get('grid').setAll({
                stroke: red,
                strokeOpacity: 1,
                strokeWidth: 3,
                location: 1,
                visible: true
            });
        }




        //series.bullets.push((root) => {
        //    var circle = am5.Circle.new(root, {
        //        radius: 5,
        //        fill: series.get("fill")
        //    });
        //    circle.events.on("click", (ev) => {
        //        let transaction = ev.target.dataItem.dataContext as ITransaction;
        //        let index = scale.transactions.indexOf(transaction);
        //        console.log(index);
        //        this.openTransaction(transaction);
        //    });
        //    return am5.Bullet.new(root, {
        //        sprite: circle
        //    });
        //});

        //var scaleNotices = _.intersectionWith(this.notices, scale.transactions, (n, t) => {

        //    return n.transactionId === t.id;
        //});

        //_.forEach(scaleNotices, (value) => {
        //    series.events.once("datavalidated", () => {
        //        let axisPosition = this.xAxis.dateToPosition(new Date(_.find(scale.transactions, function (t) { return t.id === value.transactionId }).time));
        //        let seriesDataItem = this.xAxis.getSeriesItem(series, axisPosition, 0);
        //        if (seriesDataItem !== undefined) {
        //            series.addBullet(seriesDataItem, am5.Bullet.new(root, {
        //                stacked: "auto",
        //                sprite: am5.Triangle.new(root, {
        //                    width: 22,
        //                    height: 18,
        //                    fill: am5.color(0xff0000),
        //                    stroke: series.get("stroke"),
        //                    rotation: 180
        //                })
        //            }));
        //        }
        //    });
        //});

        const tooltip = series.set('tooltip',
            am5.Tooltip.new(root, {
                pointerOrientation: "vertical",
                getFillFromSprite: false,
                getStrokeFromSprite: true,
                autoTextColor: false,
            }));

        tooltip.get("background").setAll({
            fill: am5.color(16777215),
            strokeWidth: 2,
        });
        tooltip.label.setAll({
            text: "[bold; fontSize:16px]{name}[/]\n[bold; fontSize:14px]{time.formatDate('dd.MM.yy HH:mm')}[/]\n[bold]Привес: [/]{relativeWeight}[/]",
            fill: am5.color(0),
        });

        tooltip.label.adapters.add("text", (text, target) => {
            if (this.TooltipConfig.isWeightCheked) text += '\n[bold]Вес: [/]{weight}[/]';
            if (this.TooltipConfig.isAirTemperatureCheked) text += '\n[bold]Температура возд.: [/]{airTemperature}[/]';
            if (this.TooltipConfig.isHumidityCheked) text += '\n[bold]Влажность: [/]{humidity}[/]';
            if (this.TooltipConfig.isPressureCheked) text += '\n[bold]Давление: [/]{pressure}[/]';
            if (this.TooltipConfig.isChargeCheked) text += '\n[bold]Заряд: [/]{charge}[/]';
            if (this.TooltipConfig.isSignalLevelCheked) text += '\n[bold]Уровень сигнала: [/]{signalLevel}[/]';
            if (this.TooltipConfig.isSystemTemperatureCheked) text += '\n[bold]Температура сист.: [/]{systemTemperature}[/]';

            if (target.dataItem === undefined) return text;

            for (let item of (target.dataItem.dataContext as ITransaction).notices) {
                text += `\n${item.type}`;
            }

            //if (this.notices.length !== 0) {
            //    let currentNotices = this.notices.filter(notice => notice.transactionId === (target.dataItem.dataContext as ITransaction).id);

            //    if (currentNotices.length !== 0) {
            //        for (let item of currentNotices) {
            //            text += `\n${item.type}`;
            //        }
            //    }
            //}

            return text;
        });


        series.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['id', 'weight', 'relativeWeight', 'charge', 'signalLevel', 'airTemperature', 'systemTemperature', 'humidity', 'pressure'],
            dateFields: ["time"],
            dateFormat: "i"
        });


        series.data.setAll(scale.transactions);

        //let itemswithAlerts = _.intersectionWith(series.dataItems, this.notices, (i, n) => {
        //    let transaction = i.dataContext as ITransaction;
        //    return transaction.id === n.transactionId;
        //})

        _.forEach(series.dataItems, (item) => {
            console.log(item);
            var circle = am5.Circle.new(root, {
                radius: 5,
                fill: series.get("fill")
            });
            circle.events.on("click", (ev) => {
                let transaction = ev.target.dataItem.dataContext as ITransaction;
                let index = scale.transactions.indexOf(transaction);
                console.log(index);
                this.openTransaction(transaction);
            });
            series.addBullet(item, am5.Bullet.new(root, {
                locationY: 0.5,
                stacked: "up",
                sprite: circle
            }));

            if ((item.dataContext as ITransaction).notices.length !== 0) {
                series.addBullet(item, am5.Bullet.new(root, {
                    locationY: 0.5,
                    stacked: "up",
                    sprite: am5.Triangle.new(root, {
                        width: 12,
                        height: 12,
                        fill: am5.color(0xff0000),
                        stroke: series.get("stroke"),
                        rotation: 180
                    })
                }));
            }
        });

        //_.forEach(itemswithAlerts, (item) => {
        //    series.addBullet(item, am5.Bullet.new(root, {
        //        locationY:0.5,
        //        stacked: "up",
        //        sprite: am5.Triangle.new(root, {
        //            width: 12,
        //            height: 12,
        //            fill: am5.color(0xff0000),
        //            stroke: series.get("stroke"),
        //            rotation: 180
        //        })
        //    }));
        //})
    }




    addYaxis = () => {
        this.chart.series.each((s) => s.axisRanges.clear());
        this.createYAxis();
        for (let item of this.filtredDataSet) {
            this.createSeries(item, this.root);
        }
    };

    deleteYaxis = () => {
        this.currentYAxis.series.forEach((s) => s.dispose());
        this.chart.yAxes.removeValue(this.currentYAxis);
        this.setListOfYAxes(this.chart.yAxes);
        this.currentYAxis = this.chart.yAxes.getIndex(this.chart.yAxes.length - 1) as ChartYaxis;
    };

    initialize = async (user: IUser) => {
        if (user.id === undefined) return;
        console.log('init begin');
        this.setUser(user);
        this.tooltipConfig = await TooltipConfigService.getConfig(this.user.id);
        await this.fetchData();

        //for (let item of this.dataSet) {
        //    this.notices = this.notices.concat(await NoticeService.GetNotices(item.id));
        //}

        this.createChart();
        this.createYAxis();
        this.createXAxis();

        for (let item of this.filtredDataSet) {
            this.createSeries(item, this.root);
        }

        if (this.chart.series.length !== 0) {

            this.legend.data.setAll(this.chart.series.values);
        }
        console.log('init end');
    };

    clearSeries = () => {

        while (this.chart.series.length !== 0) {
            let series = this.chart.series.pop();
            while (series.axisRanges.length !== 0) {
                let range = series.axisRanges.pop();
                range.axisDataItem.get('grid').set('visible', false);
                range.axisDataItem.get('label').dispose();
            }
            series.dispose();
        }
        this.currentYAxis.axisRanges.clear();
        this.chart.get('colors').reset();
    };

    refreshSeries = async () => {
        this.clearSeries();

        for (let axis of this.chart.yAxes) {

            this.setCurrentYAxis(axis as ChartYaxis);

            for (let item of this.filtredDataSet) {
                this.createSeries(item, this.root);
            }
        }
        this.legend.data.setAll(this.chart.series.values);
    };

    changeSeriesCategory = () => {
        for (let item of this.currentYAxis.series) {
            item.set('valueYField', this.category);
            item.data.setAll(this.dataSet.find(e => e.name === item.get('name')).transactions);
        }
        this.setYLabelText();
    };

    setYLabelText = () => {
        let textForLabel = '';
        if (this.category === 'weight') { textForLabel = "⚖  "; }
        else if (this.category === 'relativeWeight') { textForLabel = "⚖️Δ"; }
        else if (this.category === 'airTemperature') { textForLabel = "🌡    "; }
        else if (this.category === 'humidity') { textForLabel = "💧    "; }
        else if (this.category === 'pressure') { textForLabel = "⏲  "; }
        else if (this.category === 'systemTemperature') { textForLabel = "🌡    "; }
        else if (this.category === 'charge') { textForLabel = "🔋    "; }
        else if (this.category === 'signalLevel') { textForLabel = "📡   "; }

        this.currentYLabel.set('text', textForLabel);
    };


    //Модальное окно
    setCurrentModalContent(value: any) {
        this.currentModalContent = value;
    }

    get CurrentModalContent(): any {
        return this.currentModalContent;
    }

    setCurrentModal(value: ModalViewModel) {
        this.currentModal = value;
    }

    get CurrentModal(): ModalViewModel {
        return this.currentModal;
    }
    //--Модальное окно
    openTooltipOptions = () => {
        let model = new TooltipOptionsViewModel(this.tooltipConfig);
        model.setClose(() => { this.currentModal.setIsOpen(false); });
        this.currentModal.setChildren(React.createElement(TooltipOptions, { viewModel: model }));
        this.currentModal.setIsOpen(true);
    };

    openTransaction = (transaction: ITransaction) => {
        let model = new TransactionViewViewModel();
        model.setTransaction(transaction);
        model.setOnClose(() => { this.currentModal.setIsOpen(false); });
        this.currentModal.setChildren(React.createElement(TransactionView, { model: model }));
        this.currentModal.setIsOpen(true);
    };








    getProperty(transaction: ITransaction, key: string) {
        return transaction[key as keyof ITransaction];
    }

    randomColor = (): string => {
        let result = '#';
        for (let i = 1; i < 4; i++) {
            result += Math.floor(Math.random() * 256).toString(16);
        }
        return result;
    };

}
