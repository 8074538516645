import React from "react";
import AboutScales from "./components/AboutScales/AboutScales";
import Administrator from "./components/Administrator/Administrator";
import Home from "./components/Home";
import LoginForm from "./components/LoginForm";
import Profile from "./components/Profile/Profile";
import Scales from "./components/Scales";
import UserCharts from "./components/UserChart/UserCharts";

const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/scales',
        element: <Scales/>
    },
    {
        path: '/login',
        element: <LoginForm />
    },
    {
        path: '/scales/user-charts',
        element: <UserCharts />
    },
    {
        path: '/administrator',
        element: <Administrator/>
    },
    {
        path: '/profile',
        element: <Profile/>
    },
    {
        path: '/about',
        element: <AboutScales/>
    }
];

export default AppRoutes;
