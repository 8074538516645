import { makeAutoObservable } from "mobx";
import ViewModel from "../../../ViewModel";
import src from '../../../../assets/logo.png';
import FirmwareService from "../../../../services/FirmwareService";





export default class FirmwareVM implements ViewModel {
    onClose: () => void;
    formFile: File;
    file: string =src;

    constructor(onClose: () => void) {
        this.onClose = onClose;
        makeAutoObservable(this);
    }

    setFormFile = (value: File) => {
        this.formFile = value;
    }

    setFile = (value: string) => {
        this.file = value;
    }

    sendFile = () => {
        FirmwareService.upload(this.formFile);
        this.onClose();
    }
    sendFileBeta = () => {
        FirmwareService.uploadBeta(this.formFile);
        this.onClose();
    }
}