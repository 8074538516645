import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import AcceptButton from "../Buttons/AcceptButton/AcceptButton";
import CancelButton from "../Buttons/CancelButton/CancelButton";
import { Row } from "../styledElements/styledContainers";
import { Content, SwitchInput, SwitchToggler } from "./TooltipOptionsStyles";
import TooltipOptionsViewModel from "./TooltipOptionsViewModel";




interface Props {
    viewModel: TooltipOptionsViewModel;
}


const TooltipOptions: FC<Props> = ({ viewModel }) => {
    if (!viewModel.IsOpen) {
        return null;
    }


    return (
        
        <Content>
            <Row>
                <label>Настройки отображения информации на графике</label>
            </Row>
            <Row>
                <label>Вес:</label>
                <label>
                    <SwitchInput
                        onChange={e => viewModel.setIsWeightCheked(e.target.checked)}
                        checked={viewModel.IsWeightCheked}
                        type='checkbox'
                    />
                    <SwitchToggler />
                </label>
            </Row>
            <Row>
                <label>Температура воздуха:</label>
                <label>
                    <SwitchInput
                        onChange={e => viewModel.setIsAirTemperatureCheked(e.target.checked)}
                        checked={viewModel.IsAirTemperatureCheked}
                        type='checkbox'
                    />
                    <SwitchToggler />
                </label>
            </Row>
            <Row>
                <label>Влажность:</label>
                <label>
                    <SwitchInput
                        onChange={e => viewModel.setIsHumidityCheked(e.target.checked)}
                        checked={viewModel.IsHumidityCheked}
                        type='checkbox'
                    />
                    <SwitchToggler />
                </label>
            </Row>
            <Row>
                <label>Давление:</label>
                <label>
                    <SwitchInput
                        onChange={e => viewModel.setIsPressureCheked(e.target.checked)}
                        checked={viewModel.IsPressureCheked}
                        type='checkbox'
                    />
                    <SwitchToggler />
                </label>
            </Row>
            <Row>
                <label>Заряд:</label>
                <label>
                    <SwitchInput
                        onChange={e => viewModel.setIsChargeCheked(e.target.checked)}
                        checked={viewModel.IsChargeCheked}
                        type='checkbox'
                    />
                    <SwitchToggler />
                </label>
            </Row>
            <Row>
                <label>Температура системы:</label>
                <label>
                    <SwitchInput
                        onChange={e => viewModel.setIsSystemTemperatureCheked(e.target.checked)}
                        checked={viewModel.IsSystemTemperatureCheked}
                        type='checkbox'
                    />
                    <SwitchToggler />
                </label>
            </Row>
            <Row>
                <label>Уровень сигнала:</label>
                <label>
                    <SwitchInput
                        onChange={e => viewModel.setIsSignalLevelCheked(e.target.checked)}
                        checked={viewModel.IsSignalLevelCheked}
                        type='checkbox'
                    />
                    <SwitchToggler />
                </label>
            </Row>
            <Row>
                <AcceptButton onClick={viewModel.confirm} size='40px'></AcceptButton>
                <CancelButton onClick={viewModel.Close} size='40px'></CancelButton>
            </Row>
        </Content>
    );
}
export default observer(TooltipOptions);