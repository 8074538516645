import { makeAutoObservable } from "mobx";
import React from "react";
import { ReactNode } from "react";
import AddScale from "../../components/Profile/AddScale/AddScale";
import AddScaleViewModel from "../../components/Profile/AddScale/AddScaleViewModel";
import ScaleOptions from "../../components/Profile/ScaleOptions/ScaleOptions";
import ScaleOptionsViewModel from "../../components/Profile/ScaleOptions/ScaleOptionsViewModel";
import ViewModel from "../../components/ViewModel";
import IScale from "../../models/IScale";
import ScaleService from "../../services/ScaleService";
import Store from "../../store/store";





export default class ScaleTableViewModel implements ViewModel {
    store: Store;
    onClose?: () => void;

    scales: IScale[] = [] as IScale[];
    modalContent: ReactNode;
    modalHeader: string;

    openConfirm: boolean = false;
    openModal: boolean = false;

    currentScaleId: number | null;



    constructor(store: Store, onClose?: () => void) {
        this.onClose = onClose
        this.store = store;
        makeAutoObservable(this);
    }



    setScales = (value: IScale[]) => {
        this.scales = value;
    }

    setOpenModal = (value: boolean) => {
        this.openModal = value;
    }

    setModalHeader = (value: string) => {
        this.modalHeader = value;
    }

    setModalContent = (value: ReactNode) => {
        this.modalContent = value;
    }



    fetchData = async () => {
        const result = await ScaleService.getScales();
        this.setScales(result);
    }

    add = () => {
        this.setModalContent(React.createElement(AddScale, {
            viewModel: new AddScaleViewModel(this.closeModal)
        }));
        this.setModalHeader('Добавление весов.');
        this.setOpenModal(true);
    }

    edit = (scaleId: number) => {
        this.setModalContent(React.createElement(ScaleOptions, {
            viewModel: new ScaleOptionsViewModel(scaleId, undefined, this.closeModal)
        }));
        this.setModalHeader('Настройка весов.');
        this.setOpenModal(true);
    }

    deleteScale = async () => {
        ScaleService.clearScale(this.currentScaleId);
        await this.fetchData();
        this.hideConfirm();
    }

    showConfirm = (scaleId: number) => {
        this.currentScaleId = scaleId;
        this.openConfirm = true;
    }

    hideConfirm = () => {
        this.openConfirm = false;
        this.currentScaleId = null;
    }

    closeModal = async () => {
        this.setOpenModal(false);
        this.setModalContent(null);
        await this.fetchData();
    }
}